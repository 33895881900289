/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import {
  FormGroup,
  Row,
  Col,
  Label,
  Input,
  // Button
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { thingActions } from '../../../../actions/thing'
import { AppSwitch } from '@coreui/react'
import thingHelper from '../variable'


import { connect } from 'react-redux';
class EditIoAiChannel extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      typeCodeType: ''
    }
    this.onChange = this.onChange.bind(this);
    this.onChangeModbus = this.onChangeModbus.bind(this);
  }
  onChange(key, value) {
    this.props.thingAction.updateEditChannelData(key, value)
  }
  onChangeModbus(key, value) {
    this.props.thingAction.updateEditIoChannelModbusData(key, value)
  }


  render() {
    const enable = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'enable' });
    const samplingInterval = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'samplingInterval' });
    const reportingInterval = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'reportingInterval' });
    const highAlarm = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'highAlarm' });
    const highAlarmAlarmThreshold = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'highAlarmAlarmThreshold' });
    const highAlarmWarningThreshold = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'highAlarmWarningThreshold' });
    const lowAlarm = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'lowAlarm' });
    const lowAlarmAlarmThreshold = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'lowAlarmAlarmThreshold' });
    const lowAlarmWarningThreshold = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'lowAlarmWarningThreshold' });
    const typeCode = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'typeCode' });
    const typeCodeType = thingHelper.aiTypeCode.find((data) => { return parseInt(data.value, 10) === typeCode.value }) || thingHelper.aiTypeCode[0]
    // const typeSelect = thingHelper.typeTable.map((item2, idx2) => {
    //   return <option value={item2.value} key={idx2}>{item2.type}</option>
    // })
    const typeCodeSelect = thingHelper.aiTypeCode.map((item, idx) => {
      if (item.channelType === typeCodeType.channelType) {
        return <option value={item.value} key={idx}>{item.text}</option>
      }
    })
    return (
      <Row>
        <Col>
          <FormGroup row>
            <Col md="2">
              <Label>Name</Label>
              <br></br>
              <Input
                type="text"
                name="alias"
                value={this.props.edit.data.alias}
                onChange={e => { this.onChange('alias', e.target.value) }}
              />
            </Col>
            <Col md="2">
              <Label>Enable</Label>
              <br></br>
              <AppSwitch
                className={'mx-1'}
                size="sm"
                checked={(!!enable.value)}
                onChange={e => { this.onChangeModbus('enable', +(e.target.checked)) }}
                label />
            </Col>
            <Col md="3">
              <Label>Sampling interval(ms)</Label>
              <br></br>
              <Input
                type="number"
                name="samplingInterval"
                value={samplingInterval.value}
                disabled={(!(!!enable.value))}
                onChange={e => { this.onChangeModbus('samplingInterval', parseInt(e.target.value, 10)) }}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            {/* <Col md="2">
              <Label>Input type</Label>
              <br></br>
              <Input
                type="select"
                value={typeCodeType.type}
                disabled={(!(!!enable.value))}
                onChange={e => { this.onChangeModbus('typeCode', parseInt(e.target.value, 10)) }}
              >
                {typeSelect}
              </Input>
            </Col> */}
            <Col md="3">
              <Label>Range</Label>
              <br></br>
              <Input
                type="select"
                value={typeCode.value}
                disabled={(!(!!enable.value))}
                onChange={e => { this.onChangeModbus('typeCode', parseInt(e.target.value, 10)) }}
              >
                {typeCodeSelect}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="8" className="orio-device-text">
              <h4>Anomaly Detection</h4>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="3">
              <Label>Reporting interval(ms)</Label>
              <br></br>
              <Input
                type="number"
                name="reportingInterval"
                value={reportingInterval.value}
                disabled={(!(!!enable.value))}
                onChange={e => { this.onChangeModbus('reportingInterval', parseInt(e.target.value, 10)) }}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="2">
              <Label>High alarm</Label>
              <br></br>
              <AppSwitch
                className={'mx-1'}
                size="sm"
                checked={(!!highAlarm.value)}
                disabled={(!(!!enable.value))}
                onChange={e => { this.onChangeModbus('highAlarm', +(e.target.checked)) }}
                label />
            </Col>
            <Col md="3">
              <Label>Alarm threshold(°C)</Label>
              <br></br>
              <Input
                type="number"
                name="highAlarmAlarmThreshold"
                value={highAlarmAlarmThreshold.value}
                disabled={(!(!!enable.value && !!highAlarm.value))}
                onChange={e => { this.onChangeModbus('highAlarmAlarmThreshold', parseInt(e.target.value, 10)) }}
              />
            </Col>
            <Col md="3">
              <Label>Warning threshold(°C)</Label>
              <br></br>
              <Input
                type="number"
                name="highAlarmWarningThreshold"
                value={highAlarmWarningThreshold.value}
                disabled={(!(!!enable.value && !!highAlarm.value))}
                onChange={e => { this.onChangeModbus('highAlarmWarningThreshold', parseInt(e.target.value, 10)) }}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="2">
              <Label>Low alarm</Label>
              <br></br>
              <AppSwitch
                className={'mx-1'}
                size="sm"
                checked={(!!lowAlarm.value)}
                disabled={(!(!!enable.value))}
                onChange={e => { this.onChangeModbus('lowAlarm', +(e.target.checked)) }}
                label />
            </Col>
            <Col md="3">
              <Label>Alarm threshold(°C)</Label>
              <br></br>
              <Input
                type="number"
                name="lowAlarmAlarmThreshold"
                value={lowAlarmAlarmThreshold.value}
                disabled={(!(!!enable.value && !!lowAlarm.value))}
                onChange={e => { this.onChangeModbus('lowAlarmAlarmThreshold', parseInt(e.target.value, 10)) }}
              />
            </Col>
            <Col md="3">
              <Label>Warning threshold(°C)</Label>
              <br></br>
              <Input
                type="number"
                name="lowAlarmWarningThreshold"
                value={lowAlarmWarningThreshold.value}
                disabled={(!(!!enable.value && !!lowAlarm.value))}
                onChange={e => { this.onChangeModbus('lowAlarmWarningThreshold', parseInt(e.target.value, 10)) }}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}


function mapStateToProps(state) {
  return {
    thing: state.thing.thingData.info,
    channels: state.thing.thingData.channels,
    edit: state.thing.editIoChannel,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    thingAction: bindActionCreators(thingActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditIoAiChannel);