const initialState = {
  singleChart: {
    realChart: false,
    id: '',
    alias: '',
    chart: {
      label: [],
      data: []
    },
    realTimeChart: {
      label: [],
      data: []
    },
  }
}

const viewChart = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_SINGLE_CHART_OBJECT':
      return Object.assign({}, state, {
        singleChart: Object.assign({}, state.singleChart, {
          [action.key]: action.value
        })
      });
    case 'CHANGE_REAL_TIME_CHART':
      return Object.assign({}, state, {
        singleChart: Object.assign({}, state.singleChart, {
          realChart: true,
          chart: Object.assign({}, state.singleChart.chart, {
            label: [],
            data: []
          })
        })
      });
    case 'CANCEL_REAL_TIME_CHART':
      return Object.assign({}, state, {
        singleChart: Object.assign({}, state.singleChart, {
          realChart: false,
        })
      });
    case 'UPDATE_CHART_DATA_BY_SOCKET':
      let newLabel = state.singleChart.chart.label
      let newData = state.singleChart.chart.data

      newLabel.push(parseInt(action.value.label, 10))
      newData.push(parseFloat(action.value.data, 10))
      if (newLabel.length > 10) { newLabel.splice(0, 1) }
      if (newData.length > 10) { newData.splice(0, 1) }
      return Object.assign({}, state, {
        singleChart: Object.assign({}, state.singleChart, {
          chart: Object.assign({}, state.singleChart.chart, {
            label: newLabel,
            data: newData
          })
        })
      });
    case 'CLEAR_CHART':
      return initialState;
    default:
      return state;
  }
}


export default viewChart