import location from './location';
import loading from './loading';
// import rule from './rule';
// import abnormal from './abnormalRecord'
// import history from './history'
// import member from './member'
import thing from './thing'
import user from './user'
import createDevice from './createDevice'
import viewChart from './viewChart'
import map from './map'
import errorMessage from './errorMessage';

import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    location,
    loading,
    // rule,
    // abnormal,
    // history,
    // member,
    map,
    thing,
    user,
    createDevice,
    viewChart,
    errorMessage
});

export default rootReducer;