const initialState = {
  things: [],
  thingModels: [],
  search: false,
  searchList: [],
  action: {
    hideInfo: false,
    editInfo: false,
    editGwConfig: false,
  },
  viewModbus: '',
  viewSerialPort: 1,
  editImage: false,
  tmpImage: [],
  thingData: {
    info: {
      id: '',
      coordinates: [],
      labels: [],
      images: []
    },
    channels: [],
    gwChannels: [],
    gwConfigs: {
      serialPort: {
        action: {},
        modbusAddress: []
      },
      modbusConfig: [],
    },
    gwEditConfigs: {
      serialPort: {
        action: {},
        modbusAddress: []
      },
      modbusConfig: [],
    },
    forcing: {},
    cjc: {}
  },
  editIoChannel: {
    open: false,
    channelId: '',
    type: '',
    data: {
      alias: '',
      modbusSetting: [],
    }
  },
  editGwChannel: {
    open: false,
    channelId: '',
    type: '',
    data: {
      alias: '',
      unit: '',
    }
  },
  deleteThing: false,
  uploadConfig: false,
  gwConfigCover: false,
  tmpConfig: []
}

const thing = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_THING_OBJECT':
      return Object.assign({}, state, { [action.key]: action.value });
    case 'UPDATE_THINGS_CONNECT_STATUS':
      let thingList = state.things
      thingList.map((thing) => {
        if (thing.id === action.key) {
          thing.status.online = action.value;
        }
        return thing;
      });
      return Object.assign({}, state, { things: thingList });
    case 'UPDATE_THING_INFO_DATA':
      return Object.assign({}, state, {
        thingData: Object.assign({}, state.thingData, {
          [action.key]: action.value
        })
      });
    case 'COVER_THING_INFO_DATA':
      return Object.assign({}, state, {
        thingData: Object.assign({}, state.thingData, {
          [action.key]: Object.assign({}, state.thingData[action.key], {
            modbusConfig: action.value.modbusConfig
          })
        })
      });
    case 'UPDATE_THING_ACTION_DATA':
      return Object.assign({}, state, {
        action: Object.assign({}, state.action, {
          [action.key]: action.value
        })
      });
    case 'INITINAL_THING_DATA':
      return Object.assign({}, state, {
        thingData: initialState.thingData
      });
    case 'UPDATE_EDIT_IO_CHANNEL_OBJECT':
      return Object.assign({}, state, {
        editIoChannel: Object.assign({}, state.editIoChannel, {
          [action.key]: action.value
        })
      });
    case 'UPDATE_EDIT_GW_CHANNEL_OBJECT':
      return Object.assign({}, state, {
        editGwChannel: Object.assign({}, state.editGwChannel, {
          [action.key]: action.value
        })
      });
    case 'UPDATE_EDIT_CHANNEL_DATA_OBJECT':
      return Object.assign({}, state, {
        editIoChannel: Object.assign({}, state.editIoChannel, {
          data: Object.assign({}, state.editIoChannel.data, {
            [action.key]: action.value
          })
        })
      });
    case 'UPDATE_EDIT_GATEWAY_CHANNEL_DATA_OBJECT':
      return Object.assign({}, state, {
        editGwChannel: Object.assign({}, state.editGwChannel, {
          data: Object.assign({}, state.editGwChannel.data, {
            [action.key]: action.value
          })
        })
      });
    case 'UPDATE_EDIT_IO_CHANNEL_MODBUS_OBJECT':
      let modbusConfig = state.editIoChannel.data.modbusSetting
      modbusConfig.map((data) => {
        if (data.alias === action.key) {
          data.value = action.value
        }
        return data
      })
      return Object.assign({}, state, {
        editIoChannel: Object.assign({}, state.editIoChannel, {
          data: Object.assign({}, state.editIoChannel.data, {
            modbusSetting: modbusConfig
          })
        })
      });
    case 'THING_OPEN_CHANNEL_COMMAND':
      let channelsList = state.thingData.channels
      channelsList.map((channel) => {
        if (channel.id === action.key) {
          return channel.openCommand = action.value
        }
        return channel
      })
      return Object.assign({}, state, {
        thingData: Object.assign({}, state.thingData, {
          channels: channelsList
        })
      });
    case 'THING_OPEN_GATEWAY_CHANNEL_COMMAND':
      let gwChannelsList = state.thingData.gwChannels
      gwChannelsList.map((command) => {
        command.channels.map((channel) => {
          if (channel.id === action.key) {
            return channel.openCommand = action.value
          }
          return channel
        })
        return command
      })
      return Object.assign({}, state, {
        thingData: Object.assign({}, state.thingData, {
          gwChannels: gwChannelsList
        })
      });
    case 'UPDATE_THING_INFO_IMAGE':
      let updateData = state.thingData
      updateData.info[action.key] = action.value
      return Object.assign({}, state, { thingData: updateData });

    case 'UPDATE_CHANNEL_DATA_BY_SOCKET':
      let newChannelData = state.thingData.channels
      action.data.map((socketData) => {
        const channelIndex = newChannelData.findIndex((channels) => { return channels.id === socketData.id })
        if (channelIndex !== -1) {
          if (socketData.value !== undefined) {
            newChannelData[channelIndex].value = socketData.value
          }
          socketData.data.map((modbus) => {
            const configIndex = newChannelData[channelIndex].data.findIndex((config) => { return config.id === modbus.id })
            if (configIndex !== -1) {
              newChannelData[channelIndex].data[configIndex].value = modbus.value
            }
            return modbus
          })
        }
        return socketData
      })
      return Object.assign({}, state, {
        thingData: Object.assign({}, state.thingData, {
          channels: newChannelData
        })
      });
    case 'UPDATE_DEVICE_INFO_BY_SOCKET':
      let newInfo = state.thingData.info
      console.log({ key: action.key, value: action.value })
      newInfo[action.key] = action.value
      return Object.assign({}, state, {
        thingData: Object.assign({}, state.thingData, {
          info: newInfo
        })
      });
    case 'UPDATE_GW_CHANNEL_DATA_BY_SOCKET':
      let newGwChannelData = state.thingData.gwChannels
      action.data.map((socketData) => {
        newGwChannelData.map((command) => {
          const channelIndex = command.channels.findIndex((channel) => { return channel.id === socketData.id })
          if (channelIndex !== -1) {
            command.channels[channelIndex].value = socketData.value
          }
          return command
        })
        return socketData
      })
      return Object.assign({}, state, {
        thingData: Object.assign({}, state.thingData, {
          gwChannels: newGwChannelData
        })
      });
    case 'EDIT_GATEWAY_SERIAL_PORT_CONFIG':
      let defaultSerialPort = state.thingData.gwConfigs.serialPort
      let newSerialPort = state.thingData.gwEditConfigs.serialPort
      newSerialPort.modbusAddress.map((modbus) => {
        if (modbus.alias === action.key) {
          modbus.value = action.value
        }
        return modbus
      })
      if (JSON.stringify(newSerialPort.modbusAddress) !== JSON.stringify(defaultSerialPort.modbusAddress)) {
        newSerialPort.action.edit = true
      } else {
        newSerialPort.action.edit = false
      }
      return Object.assign({}, state, {
        thingData: Object.assign({}, state.thingData, {
          gwEditConfigs: Object.assign({}, state.thingData.gwEditConfigs, {
            serialPort: newSerialPort
          })
        })
      });
    case 'EDIT_GATEWAY_MODBUS_CONFIG':
      // let defaultModbusConfig = state.thingData.gwConfigs.modbusConfig
      let newModbusConfig = state.thingData.gwEditConfigs.modbusConfig
      newModbusConfig.map((modbusConfig) => {
        if (action.index === modbusConfig.index) {
          switch (action.editType) {
            case 'name':
              modbusConfig.name.alias = action.value
              if (!modbusConfig.config.action.create && !modbusConfig.config.action.delete) {
                modbusConfig.name.edit = true
              }
              return modbusConfig
            case 'retain':
              modbusConfig.retain.value = action.value
              if (!modbusConfig.config.action.create && !modbusConfig.config.action.delete) {
                modbusConfig.config.action.edit = true
              }
              return modbusConfig
            case 'enable':
              modbusConfig.enable.value = action.value

              return modbusConfig
            case 'config':
              modbusConfig.config.data.map((config) => {
                if (config.alias === action.key) {
                  config.value = action.value
                  if (action.key === 'functionCode') {
                    if (action.value === 1 || action.value === 3) {
                      modbusConfig.retain.value = false;
                    }
                  }
                }
                return config
              })
              if (!modbusConfig.config.action.create) {
                modbusConfig.config.action.edit = true
              }
              return modbusConfig
            case 'channels':
              modbusConfig.channels.data.map((channel) => {
                if (channel.id === action.channelId) {
                  channel[action.key] = action.value
                  channel.action.edit = true
                }
                return channel
              })
              return modbusConfig
            default:
              return modbusConfig
          }
        }
        return modbusConfig
      })
      //action index editType key value channelId
      return Object.assign({}, state, {
        thingData: Object.assign({}, state.thingData, {
          gwEditConfigs: Object.assign({}, state.thingData.gwEditConfigs, {
            modbusConfig: newModbusConfig
          })
        })
      });
    case 'EDIT_GATEWAY_MODBUS_CHANNELS':
      let newModbusConfig2 = state.thingData.gwEditConfigs.modbusConfig
      newModbusConfig2.map((modbusConfig) => {
        if (modbusConfig.index === action.key) {
          modbusConfig.channels.data = action.value
        }
        return modbusConfig
      })
      //action index editType key value channelId
      return Object.assign({}, state, {
        thingData: Object.assign({}, state.thingData, {
          gwEditConfigs: Object.assign({}, state.thingData.gwEditConfigs, {
            modbusConfig: newModbusConfig2
          })
        })
      });
    case 'CREATE_GATEWAY_MODBUS_COMMAND':
      let newModbusConfig3 = state.thingData.gwEditConfigs.modbusConfig
      let restoreDeleteCommand = false
      let updateIndex = -1
      newModbusConfig3.map((command, idx) => {
        if (command.index === action.value.index) {
          action.value.config.action.edit = true
          updateIndex = idx
          restoreDeleteCommand = true
        }
        return command
      })
      if (!restoreDeleteCommand) {
        action.value.config.action.create = true
        newModbusConfig3.push(action.value)
      } else {
        newModbusConfig3[updateIndex] = action.value
      }
      return Object.assign({}, state, {
        thingData: Object.assign({}, state.thingData, {
          gwEditConfigs: Object.assign({}, state.thingData.gwEditConfigs, {
            modbusConfig: newModbusConfig3
          })
        })
      });
    case 'DELETE_GATEWAY_MODBUS_COMMAND':
      let newModbusConfig4 = state.thingData.gwEditConfigs.modbusConfig
      let removeIndex = -1
      let removeCommand = false
      newModbusConfig4.map((modbusConfig, idx) => {
        if (modbusConfig.index === action.key) {
          if (modbusConfig.config.action.create) {
            removeIndex = idx
            removeCommand = true
          } else {
            modbusConfig.config.action.delete = action.value
            modbusConfig.enable.value = 0
          }
        }
        return modbusConfig
      })
      if (removeCommand) {
        newModbusConfig4.splice(removeIndex, 1)
      }
      return Object.assign({}, state, {
        thingData: Object.assign({}, state.thingData, {
          gwEditConfigs: Object.assign({}, state.thingData.gwEditConfigs, {
            modbusConfig: newModbusConfig4
          })
        })
      });
    default:
      return state;
  }
}


export default thing