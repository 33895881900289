/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap'
import {
  Button,
  Label,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createDeviceActions } from "../actions/createDevice";

class ErrorMessage extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {};
    this.modelClose = this.modelClose.bind(this);
  }

  modelClose() {
    this.props.action.updateReducer('CLEAR_ERROR_MESSAGE_DEVICE', '', '')
  }
  render() {
    return (
      <Modal show={this.props.errorMessage.modelOpen} onHide={this.modelClose} centered>
        <Modal.Header >
          <Modal.Title>
            Error
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>
            <Label className="orio-link-danger">{this.props.errorMessage.message}</Label>
          </h3>
        </Modal.Body>
        <Modal.Footer >
          <Button color="light" className="orio-btn" onClick={e => { this.modelClose() }}>
            Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.errorMessage,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(createDeviceActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessage);