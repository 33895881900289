const aiTypeCode = [
  // { value: '1', channelType: 'AI', inputType: 'voltage', unit: 'mV', text: '±100mV', range: [-100, 100] },
  // { value: '2', channelType: 'AI', inputType: 'voltage', unit: 'mV', text: '±500mV', range: [-500, 500] },
  // { value: '3', channelType: 'AI', inputType: 'voltage', unit: 'V', text: '±1V', range: [-1, 1] },
  { value: '4', channelType: 'AI', inputType: 'voltage', unit: 'V', text: '±5V', range: [-5, 5] },
  { value: '5', channelType: 'AI', inputType: 'voltage', unit: 'V', text: '±10V', range: [-10, 10] },
  { value: '6', channelType: 'AI', inputType: 'voltage', unit: 'V', text: '0~5V', range: [0, 5] },
  { value: '7', channelType: 'AI', inputType: 'voltage', unit: 'V', text: '0~10V', range: [0, 10] },
  { value: '8', channelType: 'AI', inputType: 'voltage', unit: 'V', text: '1~5V', range: [1, 5] },
  { value: '9', channelType: 'AI', inputType: 'voltage', unit: 'V', text: '2~10V', range: [2, 10] },
  // { value: '257', channelType: 'AI', inputType: 'current', unit: 'mA', text: '±20mA', range: [-20, 20] },
  { value: '258', channelType: 'AI', inputType: 'current', unit: 'mA', text: '0~20mA', range: [0, 20] },
  { value: '259', channelType: 'AI', inputType: 'current', unit: 'mA', text: '4~20mA', range: [4, 20] },
  { value: '513', channelType: 'RTD', inputType: 'RTD', unit: '°C', text: 'Pt 100, -200 to 850 °C', range: [-200, 850] },
  { value: '514', channelType: 'RTD', inputType: 'RTD', unit: '°C', text: 'Pt 200, -200 to 850 °C', range: [-200, 850] },
  { value: '515', channelType: 'RTD', inputType: 'RTD', unit: '°C', text: 'Pt 500, -200 to 850 °C', range: [-200, 850] },
  { value: '516', channelType: 'RTD', inputType: 'RTD', unit: '°C', text: 'Pt 1000, -200 to 850 °C', range: [-200, 850] },
  { value: '517', channelType: 'RTD', inputType: 'RTD', unit: '°C', text: 'Ni100, -60 to 250 °C', range: [60, 250] },
  { value: '518', channelType: 'RTD', inputType: 'RTD', unit: '°C', text: 'Ni120, -80 to 260 °C', range: [-80, 260] },
  { value: '519', channelType: 'RTD', inputType: 'RTD', unit: '°C', text: 'Ni200, -60 to 250 °C', range: [-60, 250] },
  { value: '520', channelType: 'RTD', inputType: 'RTD', unit: '°C', text: 'Ni500, -60 to 250 °C', range: [-60, 250] },
  { value: '521', channelType: 'RTD', inputType: 'RTD', unit: '°C', text: 'Ni1000, -60 to 250 °C', range: [-60, 250] },
  { value: '528', channelType: 'RTD', inputType: 'RTD', unit: '°C', text: 'Cu10 -100 to 260 °C', range: [-100, 260] },
  { value: '529', channelType: 'RTD', inputType: 'RTD', unit: 'Ω', text: '40 Ω', range: [0, 40] },
  { value: '530', channelType: 'RTD', inputType: 'RTD', unit: 'Ω', text: '80 Ω', range: [0, 80] },
  { value: '531', channelType: 'RTD', inputType: 'RTD', unit: 'Ω', text: '150 Ω', range: [0, 150] },
  { value: '532', channelType: 'RTD', inputType: 'RTD', unit: 'Ω', text: '300 Ω', range: [0, 300] },
  { value: '533', channelType: 'RTD', inputType: 'RTD', unit: 'Ω', text: '500 Ω', range: [0, 500] },
  { value: '534', channelType: 'RTD', inputType: 'RTD', unit: 'Ω', text: '1K Ω', range: [0, 1000] },
  { value: '535', channelType: 'RTD', inputType: 'RTD', unit: 'Ω', text: '2k Ω', range: [0, 2000] },
  { value: '536', channelType: 'RTD', inputType: 'RTD', unit: 'Ω', text: '4k Ω', range: [0, 4000] },
  { value: '769', channelType: 'TC', inputType: 'TC', unit: '°C', text: 'Type J Thermocouple -210 ~ 1200 °C', range: [-210, 1200] },
  { value: '770', channelType: 'TC', inputType: 'TC', unit: '°C', text: 'Type K Thermocouple -270 ~ 1372 °C', range: [-270, 1372] },
  { value: '771', channelType: 'TC', inputType: 'TC', unit: '°C', text: 'Type T Thermocouple -270 ~ 400 °C', range: [-270, 400] },
  { value: '772', channelType: 'TC', inputType: 'TC', unit: '°C', text: 'Type E Thermocouple -270 ~ 1000 °C', range: [-270, 1000] },
  { value: '773', channelType: 'TC', inputType: 'TC', unit: '°C', text: 'Type R Thermocouple -50 ~ 1768 °C', range: [-50, 1768] },
  { value: '774', channelType: 'TC', inputType: 'TC', unit: '°C', text: 'Type S Thermocouple -50 ~ 1768 °C', range: [-50, 1768] },
  { value: '775', channelType: 'TC', inputType: 'TC', unit: '°C', text: 'Type B Thermocouple 0 ~ 1820 °C', range: [0, 1820] },
  { value: '776', channelType: 'TC', inputType: 'TC', unit: '°C', text: 'Type N Thermocouple -270 ~ 1300 °C', range: [-270, 1300] },
  { value: '777', channelType: 'TC', inputType: 'TC', unit: '°C', text: 'Type L Thermocouple -200 ~ 900 °C', range: [-200, 900] },
  { value: '784', channelType: 'TC', inputType: 'TC', unit: '°C', text: 'Type U Thermocouple -200 ~ 600 °C', range: [-200, 600] },
  { value: '785', channelType: 'TC', inputType: 'TC', unit: '°C', text: 'Type C Thermocouple 0 ~ 2315 °C', range: [0, 2315] },
  { value: '786', channelType: 'TC', inputType: 'TC', unit: 'mV', text: '±15.625 mV', range: [-15.625, 15.625] },
  { value: '787', channelType: 'TC', inputType: 'TC', unit: 'mV', text: '±31.25 mV', range: [-31.25, 31.25] },
  { value: '788', channelType: 'TC', inputType: 'TC', unit: 'mV', text: '±62.5 mV', range: [-62.5, 62.5] },
  { value: '789', channelType: 'TC', inputType: 'TC', unit: 'mV', text: '±125 mV', range: [-125, 125] },
  { value: '790', channelType: 'TC', inputType: 'TC', unit: 'mV', text: '±250 mV', range: [-250, 250] },
  { value: '791', channelType: 'TC', inputType: 'TC', unit: 'mV', text: '±500 mV', range: [-500, 500] },
  { value: '792', channelType: 'TC', inputType: 'TC', unit: 'V', text: '±1V', range: [-1, 1] },
  { value: '793', channelType: 'TC', inputType: 'TC', unit: 'V', text: '±2V', range: [-2, 2] },
]
const deviceDetail = [
  {
    title: "Device Name",
    key: "name",
    edit: true,
    type: 'text'
  },
  {
    title: "IDentity",
    key: "id",
    edit: false,
    type: 'text'
  },
  {
    title: "Secret",
    key: "secret",
    edit: false,
    type: 'text'
  },
  {
    title: "Serial Number",
    key: "sn",
    edit: false,
    type: 'text'
  },
  {
    title: "Order number",
    key: "orderNumber",
    edit: false,
    type: 'text'
  },
  {
    title: "Cellular connectivity",
    key: "cellConnectRssi",
    edit: false,
    type: 'number'
  },
  {
    title: "Bluetooth",
    key: "bluetooth",
    edit: false,
    type: 'number'
  },
  {
    title: "Coordinates",
    key: "coordinates",
    edit: true,
    type: 'number'
  },
  {
    title: "MQTT keep alive interval",
    key: "interval",
    edit: false,
    type: 'number'
  },
  {
    title: "FW version",
    key: "fwVersion",
    edit: false,
    type: 'text'
  },
  {
    title: "HW version",
    key: "hwVersion",
    edit: false,
    type: 'text'
  },
  {
    title: "IMEI",
    key: "IMEI",
    edit: false,
    type: 'text'
  },
  {
    title: "Description",
    key: "description",
    edit: true,
    type: 'textarea'
  },
]

const ioTableType = [
  {
    type: "DI",
    inputTitle: 'Events',
    boolean: true,
    normalCommand: true,
  },
  {
    type: "DO",
    inputTitle: '',
    boolean: true,
    normalCommand: true,
  },
  {
    type: "RELAY",
    inputTitle: '',
    boolean: true,
    normalCommand: true,
  },
  {
    type: "AI",
    inputTitle: '',
    boolean: false,
    normalCommand: true,
  },
  {
    type: "RTD",
    inputTitle: 'Switch',
    boolean: false,
    normalCommand: true,
  }
]

const gwTableType = [
  {
    type: "DO",
    boolean: true,
    normalCommand: true,
  },
  {
    type: "DI",
    boolean: true,
    normalCommand: false,
  },
  {
    type: "AI",
    boolean: false,
    normalCommand: false,
  },
  {
    type: "AO",
    boolean: false,
    normalCommand: true,
  }
]

const gatewaySerialPortConfig = [
  {
    alias: 'baud',
    title: 'Baud',
    type: 'number'
  },
  {
    alias: 'dataSizes',
    title: 'Data bits',
    type: 'number'
  },
  {
    alias: 'stopBits',
    title: 'Stop bits',
    type: 'number'
  },
  {
    alias: 'responseTimeout',
    title: 'Response timeout(ms)',
    type: 'number'
  },
  {
    alias: 'delayBetweenPolls',
    title: 'Delay between polls(ms)',
    type: 'number'
  },
  {
    alias: 'parity',
    title: 'Parity',
    type: 'select',
    options: [
      {
        value: 0,
        text: 'None'
      },
      {
        value: 1,
        text: 'Even'
      },
      {
        value: 2,
        text: 'Odd'
      },
    ]
  },
]
const gatewayCommandModbusConfig = [
  {
    alias: 'protocalSelection',
    title: 'Protocal Selection',
    type: 'select',
    options: [
      {
        value: 0,
        text: 'Modbus RTU Master'
      },
      {
        value: 1,
        text: 'Modbus TCP Master'
      },
    ]
  },
  {
    alias: 'netId',
    title: 'Slave ID',
    type: 'number',
    min: 1
  },
  {
    alias: 'startAddress',
    title: 'Start Address',
    type: 'number',
    min: 0
  },
  {
    alias: 'numberOfData',
    title: 'Number of data',
    type: 'number',
    min: 0,
    max: 128
  },
  {
    alias: 'functionCode',
    title: 'Table Name',
    type: 'select',
    options: [
      {
        value: 0,
        text: 'Coils(DO)'
      },
      {
        value: 1,
        text: 'Discrete Inputs(DI)'
      },
      {
        value: 3,
        text: 'Input Registers(AI)'
      },
      {
        value: 4,
        text: 'Holding Registers(AO)'
      },
    ]
  },
  {
    alias: 'typeOfData',
    title: 'Display Format',
    type: 'select',
    options: [
      {
        value: 0,
        text: 'Signed',
        interval: 1
      },
      {
        value: 1,
        text: 'Unsigned',
        interval: 1
      },
      {
        value: 4,
        text: '32 Bit signed, Big-endian',
        interval: 2
      },
      {
        value: 5,
        text: '32 Bit signed, Little-endian',
        interval: 2
      },
      {
        value: 6,
        text: '32 Bit signed, Big-endian byte swap',
        interval: 2
      },
      {
        value: 7,
        text: '32 Bit signed, Little-endian byte swap',
        interval: 2
      },
      {
        value: 8,
        text: '32 Bit Unsigned, Big-endian',
        interval: 2
      },
      {
        value: 9,
        text: '32 Bit Unsigned, Little-endian',
        interval: 2
      },
      {
        value: 16,
        text: '32 Bit Unsigned, Big-endian byte swap',
        interval: 2
      },
      {
        value: 17,
        text: '32 Bit Unsigned, Little-endian byte swap',
        interval: 2
      },
      {
        value: 18,
        text: '64 Bit signed, Big-endian',
        interval: 4
      },
      {
        value: 19,
        text: '64 Bit signed, Little-endian',
        interval: 4
      },
      {
        value: 20,
        text: '64 Bit signed, Big-endian byte swap',
        interval: 4
      },
      {
        value: 21,
        text: '64 Bit signed, Little-endian byte swap',
        interval: 4
      },
      {
        value: 22,
        text: '64 Bit Unsigned, Big-endian',
        interval: 4
      },
      {
        value: 23,
        text: '64 Bit Unsigned, Little-endian',
        interval: 4
      },
      {
        value: 24,
        text: '64 Bit Unsigned, Big-endian byte swap',
        interval: 4
      },
      {
        value: 25,
        text: '64 Bit Unsigned, Little-endian byte swap',
        interval: 4
      },
      {
        value: 32,
        text: '32 Bit Float, Big-endian',
        interval: 2
      },
      {
        value: 33,
        text: '32 Bit Float, Little-endian',
        interval: 2
      },
      {
        value: 34,
        text: '32 Bit Float, Big-endian byte swap',
        interval: 2
      },
      {
        value: 35,
        text: '32 Bit Float, Little-endian byte swap',
        interval: 2
      },
    ]
  },
  {
    alias: 'scanInterval',
    title: 'Scan Rate',
    type: 'number',
    min: 0
  },
  {
    alias: 'writeDataType',
    title: 'Function Code',
    type: 'select',
    options: [
      {
        value: 1,
        text: '01'
      },
      {
        value: 2,
        text: '02'
      },
      {
        value: 3,
        text: '03'
      },
      {
        value: 4,
        text: '04'
      },
      {
        value: 5,
        text: '05'
      },
      {
        value: 6,
        text: '06'
      },
      {
        value: 15,
        text: '15'
      },
      {
        value: 16,
        text: '16'
      },
    ]
  },
  {
    alias: 'modbusTcpIp',
    title: 'Modbus TCP IP',
    type: 'text',
  },
  {
    alias: 'modbusTcpPort',
    title: 'Modbus TCP Port',
    type: 'number',
    min: 0,
    max: 99999
  },
]
const dataTypeTable = [
  {
    value: 0,
    interval: 1
  },
  {
    value: 1,
    interval: 1
  },
  {
    value: 2,
    interval: 1
  },
  {
    value: 3,
    interval: 1
  },
  {
    value: 4,
    interval: 2
  },
  {
    value: 5,
    interval: 2
  },
  {
    value: 6,
    interval: 2
  },
  {
    value: 7,
    interval: 2
  },
  {
    value: 8,
    interval: 2
  },
  {
    value: 9,
    interval: 2
  },
  {
    value: 16,
    interval: 2
  },
  {
    value: 17,
    interval: 2
  },
  {
    value: 18,
    interval: 4
  },
  {
    value: 19,
    interval: 4
  },
  {
    value: 20,
    interval: 4
  },
  {
    value: 21,
    interval: 4
  },
  {
    value: 22,
    interval: 4
  },
  {
    value: 23,
    interval: 4
  },
  {
    value: 24,
    interval: 4
  },
  {
    value: 25,
    interval: 4
  },
  {
    value: 32,
    interval: 2
  },
  {
    value: 33,
    interval: 2
  },
  {
    value: 34,
    interval: 2
  },
  {
    value: 35,
    interval: 2
  },
  {
    value: 36,
    interval: 4
  },
  {
    value: 37,
    interval: 4
  },
  {
    value: 38,
    interval: 4
  },
  {
    value: 39,
    interval: 4
  },
]

const configTable = [
  {
    type: 'key',
    title: 'ID',

  },
  {
    type: 'name',
    title: 'Name',

  },
  {
    type: 'expected',
    title: 'Expect',

  },
  {
    type: 'reported',
    title: 'Report',

  },
]
const thingHelper = {
  deviceDetail,
  ioTableType,
  gwTableType,
  aiTypeCode,
  gatewaySerialPortConfig,
  gatewayCommandModbusConfig,
  dataTypeTable,
  configTable,
}

export default thingHelper 