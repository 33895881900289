/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap'
import {
  Button,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  FormFeedback,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createDeviceActions } from "../actions/createDevice";
import { mapActions } from '../actions/map'
import './overFlow.css'

class CreateThing extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {};
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
    this.verify = this.verify.bind(this);
    this.modelClose = this.modelClose.bind(this);
    this.createDevice = this.createDevice.bind(this)
  }
  verify() {
    if (this.props.createDevice.deviceInfo.deviceName) {
      if (this.props.createDevice.deviceInfo.deviceName.length > 1) {
        this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_VERIFY', 'deviceName', true)
      } else {
        this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_VERIFY', 'deviceName', false)
      }
    } else {
      this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_VERIFY', 'deviceName', false)
    }

    if (this.props.createDevice.deviceInfo.serialNumber) {
      if (this.props.createDevice.deviceInfo.serialNumber.length > 1) {
        this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_VERIFY', 'serialNumber', true)
      } else {
        this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_VERIFY', 'serialNumber', false)
      }
    } else {
      this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_VERIFY', 'serialNumber', false)
    }

    if (this.props.createDevice.deviceInfo.thingModel) {
      if (this.props.createDevice.deviceInfo.thingModel.length > 1) {
        this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_VERIFY', 'thingModel', true)
      } else {
        this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_VERIFY', 'thingModel', false)
      }
    } else {
      this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_VERIFY', 'thingModel', false)
    }
    if (this.props.createDevice.deviceInfo.coordinate_lng < 180 &&
      this.props.createDevice.deviceInfo.coordinate_lng > -180) {
      this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_VERIFY', 'coordinate_lng', true)
    } else {
      this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_VERIFY', 'coordinate_lng', false)
    }
    if (this.props.createDevice.deviceInfo.coordinate_lat < 90 &&
      this.props.createDevice.deviceInfo.coordinate_lat > -90) {
      this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_VERIFY', 'coordinate_lat', true)
    } else {
      this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_VERIFY', 'coordinate_lat', false)
    }

  }
  async submit() {
    await this.verify()
    if (this.props.createDevice.verify.deviceName &&
      this.props.createDevice.verify.serialNumber &&
      this.props.createDevice.verify.coordinate_lng &&
      this.props.createDevice.verify.coordinate_lat &&
      this.props.createDevice.verify.thingModel
    ) {
      const data = {
        deviceName: this.props.createDevice.deviceInfo.deviceName,
        serialNumber: this.props.createDevice.deviceInfo.serialNumber,
        coordinate_lat: parseFloat(this.props.createDevice.deviceInfo.coordinate_lat, 10),
        coordinate_lng: parseFloat(this.props.createDevice.deviceInfo.coordinate_lng, 10),
        thingModel: this.props.createDevice.deviceInfo.thingModel,
        modelData: {
          channelNum: {
            DO: parseInt(this.props.createDevice.deviceInfo.modelDO, 10),
            DI: parseInt(this.props.createDevice.deviceInfo.modelDI, 10),
            AI: parseInt(this.props.createDevice.deviceInfo.modelAI, 10),
            AO: parseInt(this.props.createDevice.deviceInfo.modelAO, 10),
          },
          gatewaySerialNum: parseInt(this.props.createDevice.deviceInfo.serialPort, 10),
        },
        labels: [
          { key: 'thingModel', value: this.props.createDevice.deviceInfo.thingModel },
          { key: 'apiKey', value: this.props.user.apiKey }
        ]
      }
      this.props.action.submitCreateDevice(data)
      this.props.action.updateReducer('CLEAR_CREATE_DEVICE', '', '')
    }
  }

  onChange(e) {

    const pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
    if (pattern.test(e.target.value)) {
      e.target.value = ''
    }
    if (e.target.name === 'modelDI' || e.target.name === 'modelDO' || e.target.name === 'modelAI' || e.target.name === 'modelAO') {
      if (parseInt(e.target.value, 10) < 0 || parseInt(e.target.value, 10) > 16) {
        e.target.value = 0;
      }
    }
    if (e.target.name === 'serialPort') {
      if (parseInt(e.target.value, 10) < 1 || parseInt(e.target.value, 10) > 8) {
        e.target.value = 1;
      }
    }
    this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_OBJECT', e.target.name, e.target.value)
    if (e.target.name === 'thingModel') {
      const updateModelData = this.props.modelList.find((model) => {
        return model.modelName === e.target.value
      }) || {
        channelNum: {
          DI: 0,
          DO: 0,
          AI: 0,
          AO: 0,
        },
        gatewaySerialNum: 1
      }
      this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_OBJECT', 'modelDI', updateModelData.channelNum.DI)
      this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_OBJECT', 'modelDO', updateModelData.channelNum.DO)
      this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_OBJECT', 'modelAI', updateModelData.channelNum.AI)
      this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_OBJECT', 'modelAO', updateModelData.channelNum.AO)
      this.props.action.updateReducer('UPDATE_CREATE_DEVICE_INFO_OBJECT', 'serialPort', updateModelData.gatewaySerialNum)
    }
    // this.setState({ [e.target.name]: e.target.value });
  }
  modelClose() {
    this.props.action.updateReducer('CLEAR_CREATE_DEVICE', '', '')
  }
  createDevice() {
    if (this.props.location === 'devices') {
      this.props.action.updateReducer('UPDATE_CREATE_DEVICE_OBJECT', 'modelOpen', true)
    }
    if (this.props.location === 'map') {
      this.props.mapAction.updateMapObject('createDevice', true)
    }
  }
  render() {
    const thingModel = this.props.modelList.map((model, idx) => {
      return <option key={idx} value={model.modelName}>{model.name}</option>
    })
    return (
      <div >
        <FormGroup row>
          <Button
            color="light"
            onClick={e => { this.createDevice() }}
            className="btn-pill button-add-thing"
            disabled={(this.props.loading)}>
            <i className="fa fa-plus "></i>
          </Button>
        </FormGroup>

        <Modal size="lg" show={this.props.createDevice.modelOpen} onHide={this.modelClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Create a New Device</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <CardBody>
                  {/* <Row>
                    <Col>
                      <FormGroup>
                        <Label for="examplePassword">Invalid input</Label>
                        <Input invalid />
                        <FormFeedback>Oh noes! that name is already taken</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col>
                      <FormGroup row>
                        <Col md="4">
                          <Label htmlFor="name">Name</Label>
                        </Col>
                        <Col xs="12" md="8">
                          <Input
                            type="text"
                            id="deviceName"
                            name="deviceName"
                            value={this.props.createDevice.deviceInfo.deviceName || ''}
                            onChange={e => { this.onChange(e) }}
                            required
                            invalid={(!this.props.createDevice.verify.deviceName)}
                          />
                          <FormFeedback>Name is required !</FormFeedback>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup row>
                        <Col md="4">
                          <Label htmlFor="name">Serial Number</Label>
                        </Col>
                        <Col xs="12" md="8">
                          <Input
                            type="text"
                            id="serialNumber"
                            name="serialNumber"
                            value={this.props.createDevice.deviceInfo.serialNumber || ''}
                            onChange={e => { this.onChange(e) }}
                            required
                            invalid={(!this.props.createDevice.verify.serialNumber)}
                          />
                          <FormFeedback>SerialNumber is required !</FormFeedback>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup row>
                        <Col md="4">
                          <Label htmlFor="name">Longitude</Label>
                        </Col>
                        <Col xs="12" md="8">
                          <Input
                            type="number"
                            id="coordinate_lng"
                            name="coordinate_lng"
                            min="-180"
                            max="180"
                            placeholder="Longitude(180 ~ -180)"
                            value={this.props.createDevice.deviceInfo.coordinate_lng || 0}
                            onChange={e => { this.onChange(e) }}
                            required
                            invalid={(!this.props.createDevice.verify.coordinate_lng)}
                          />
                          <FormFeedback>Longitude is between 180 ~ -180 !</FormFeedback>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup row>
                        <Col md="4">
                          <Label htmlFor="name">Latitude</Label>
                        </Col>
                        <Col xs="12" md="8">
                          <Input
                            type="number"
                            id="coordinate_lat"
                            name="coordinate_lat"
                            min="-90"
                            max="90"
                            placeholder="Latitude(90 ~ -90)"
                            value={this.props.createDevice.deviceInfo.coordinate_lat || 0}
                            onChange={e => { this.onChange(e) }}
                            required
                            invalid={(!this.props.createDevice.verify.coordinate_lat)}
                          />
                          <FormFeedback>Latitude is between 90 ~ -90 !</FormFeedback>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup row>
                        <Col md="4">
                          <Label htmlFor="oriolist">Thing Model</Label>
                        </Col>
                        <Col xs="12" md="8">
                          <Input
                            type="select"
                            name="thingModel"
                            id="thingModel"
                            value={this.props.createDevice.deviceInfo.thingModel || ''}
                            onChange={e => { this.onChange(e) }}
                            invalid={(!this.props.createDevice.verify.thingModel)}
                          >
                            <option value={''} disabled>Please Select Device Model</option>
                            {thingModel}
                            <option className="thing-model-other-option" value={'OTHER'} >Other</option>
                          </Input>
                          <FormFeedback>Thing Model is required !</FormFeedback>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  {(this.props.createDevice.deviceInfo.thingModel !== null) &&
                    <Row>
                      <Col>
                        <FormGroup row>
                          <Col md="4">
                          </Col>
                          <Col xs="12" md="8">
                            <Label htmlFor="oriolist">I/O</Label><br></br>
                            <FormGroup row>
                              <Col md="4">DI</Col>
                              <Col xs="12" md="8">
                                <Input
                                  disabled={(this.props.createDevice.deviceInfo.thingModel !== 'OTHER')}
                                  type="number"
                                  id="modelDI"
                                  name="modelDI"
                                  min="0"
                                  max="16"
                                  placeholder="0 ~ 16"
                                  value={this.props.createDevice.deviceInfo.modelDI || 0}
                                  onChange={e => { this.onChange(e) }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Col md="4">DO</Col>
                              <Col xs="12" md="8">
                                <Input
                                  disabled={(this.props.createDevice.deviceInfo.thingModel !== 'OTHER')}
                                  type="number"
                                  id="modelDO"
                                  name="modelDO"
                                  min="0"
                                  max="16"
                                  placeholder="0 ~ 16"
                                  value={this.props.createDevice.deviceInfo.modelDO || 0}
                                  onChange={e => { this.onChange(e) }}
                                />
                              </Col>

                            </FormGroup>
                            <FormGroup row>
                              <Col md="4">AI</Col>
                              <Col xs="12" md="8">
                                <Input
                                  disabled={(this.props.createDevice.deviceInfo.thingModel !== 'OTHER')}
                                  type="number"
                                  id="modelAI"
                                  name="modelAI"
                                  min="0"
                                  max="16"
                                  placeholder="0 ~ 16"
                                  value={this.props.createDevice.deviceInfo.modelAI || 0}
                                  onChange={e => { this.onChange(e) }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Col md="4">AO</Col>
                              <Col md="4">
                                {this.props.createDevice.deviceInfo.modelAO}
                              </Col>
                            </FormGroup>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Col md="4">
                          </Col>
                          <Col xs="12" md="8">
                            <Label htmlFor="oriolist">GW</Label><br></br>
                            <FormGroup row>
                              <Col md="4">Serial Port</Col>
                              <Col xs="12" md="8">
                                <Input
                                  disabled={(this.props.createDevice.deviceInfo.thingModel !== 'OTHER')}
                                  type="number"
                                  id="serialPort"
                                  name="serialPort"
                                  min="1"
                                  max="8"
                                  placeholder="1 ~ 8"
                                  value={this.props.createDevice.deviceInfo.serialPort || 0}
                                  onChange={e => { this.onChange(e) }}
                                />
                              </Col>

                            </FormGroup>
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  }
                </CardBody>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button color="light" className="orio-btn-cancel pill" onClick={e => { this.modelClose() }}>
              Cancel</Button>
            <Button color="primary" className="orio-btn pill" onClick={e => { this.submit() }}>
              Done</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.loading.active,
    location: state.location.page,
    createDevice: state.createDevice,
    modelList: state.thing.thingModels,
    user: state.user.info
  }
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(createDeviceActions, dispatch),
    mapAction: bindActionCreators(mapActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateThing);