import { ENV } from '../helpers/env'
import { shareFunction } from './shareFunction'

export const viewChartActions = {
  updateReducer,
  getChartRecord,
  updateChartData,
  updateChartRecord,
  closeChart,
  changeRealTimeChart,
  cancelRealTimeChart
}
function updateReducer(type, key, value) { return { type, key, value }; }

function updateChartData(key, value) {
  return dispatch => {
    dispatch(updateReducer('UPDATE_SINGLE_CHART_OBJECT', key, value))
  }
}

function getChartRecord(thingId, topic, data) {
  return dispatch => {
    fetch(`${ENV.SERVER_API}/history/get-yesterday-report/${thingId}/${topic}/${data.id}`, {
      method: 'GET',
      credentials: 'include',
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    }).then((msg) => {
      dispatch(cancelRealTimeChart())
      dispatch(updateReducer('UPDATE_SINGLE_CHART_OBJECT', 'chart', msg))
    }).catch((error) => {
      shareFunction.notify('error', 'Action Failed')
      console.log(error)
    })
  }
}

function closeChart() {
  return { type: "CLEAR_CHART" };
}

function updateChartRecord(channelId, label, data) {
  return {
    type: 'UPDATE_CHART_DATA_BY_SOCKET',
    value: {
      channelId,
      label,
      data
    },
  }
}

function changeRealTimeChart() {
  return { type: "CHANGE_REAL_TIME_CHART" };
}

function cancelRealTimeChart() {
  return { type: "CANCEL_REAL_TIME_CHART" };
}