/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import {
  Row,
  Col,
  Input,
  Popover,
  PopoverBody,
  Button
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { thingActions } from '../../../actions/thing'
import { connect } from 'react-redux';
class InputConmmand extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      commandValue: this.props.commandValue,
      openCommand: false,
      openChannel: ''
    }
    this.onchange = this.onchange.bind(this)
    this.cancelCommand = this.cancelCommand.bind(this)
    this.send = this.send.bind(this)
    this.openCommandPop = this.openCommandPop.bind(this)
  }
  cancelCommand() {
    this.onchange('openCommand', false)
    this.onchange('commandValue', 0)
  }
  onchange(key, value) {
    this.setState({ [key]: value });
  }
  openCommandPop(channelId) {
    this.onchange('openChannel', channelId)
    this.onchange('openCommand', true)
  }
  send() {
    if (this.props.retain) {
      this.props.thingAction.sendRetainCommand({
        thing: {
          id: this.props.thing.id,
          // secret: this.props.thing.info.secret,
        },
        topic: `gw-${this.props.viewSerialPort}-channel`,
        command: [{
          id: this.props.channelId,
          value: this.state.commandValue
        }]
      })
    } else {
      console.log(this.props.retain)
      let topic = '';
      switch (this.props.viewModbus) {
        case 'io-config':
          topic = 'io-command'
          break;
        case 'gateway-config':
          topic = `gw-${this.props.viewSerialPort}-command`
          break;
        default:
          topic = 'io-command'
          break;
      }
      this.props.thingAction.sendCommand({
        thing: {
          id: this.props.thing.id,
          // secret: this.props.thing.secret,
        },
        topic,
        command: [{
          id: this.props.channelId,
          value: this.state.commandValue
        }]
      })
    }
    this.onchange('openCommand', false)
  }
  UNSAFE_componentWillMount() {
    this.setState({ commandValue: this.props.commandValue })
  }
  render() {

    return (
      <div>
        <Button color="link" className="orio-link-normal py-0" id={this.props.channelId} onClick={e => { this.openCommandPop(this.props.channelId) }}>
          <i className="cui-list icons font-2xl"></i>
        </Button>
        <Popover placement="top" isOpen={(this.state.openCommand)} target={this.props.channelId}>
          <PopoverBody>
            <Row>
              <Col md="7">
                <Input
                  type="number"
                  min={this.props.min}
                  max={this.props.max}
                  value={this.state.commandValue || 0}
                  onChange={e => { this.onchange('commandValue', parseFloat(e.target.value, 10)) }} />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col md="6">
                <Button color="link" className="orio-link-normal" onClick={e => { this.cancelCommand() }}><span>Cancel</span></Button>
              </Col>
              <Col md="4">
                <Button color="primary" className="orio-btn" onClick={e => { this.send() }}><span>Send</span></Button>
              </Col>
            </Row>
          </PopoverBody>
        </Popover>
      </div>

    );
  }
}


function mapStateToProps(state) {
  return {
    thing: state.thing.thingData.info,
    viewModbus: state.thing.viewModbus,
    viewSerialPort: state.thing.viewSerialPort
  }
}

function mapDispatchToProps(dispatch) {
  return {
    thingAction: bindActionCreators(thingActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputConmmand);