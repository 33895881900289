/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ENV } from '../../helpers/env'
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap'
import {
  Button,
  CardBody,
  Col,
  Row
} from 'reactstrap';
import { logo } from '../../components/images'
class Start extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
    this.modelClose = this.modelClose.bind(this);
  }
  modelClose() {
    this.setState({ error: false })
  }
  componentWillMount() {
    if (this.props.match.params.loginStatus) {
      this.setState({ error: true })
    }
  }

  render() {

    return (
      <div className="animated fadeIn margin-top-20" align="center">
        <CardBody>
          <Row>
            <Col md="12">
              <img width="314px" src={logo.oringLogo}></img>
            </Col>
          </Row>
          <Row>
            <Col xs="12" xl="12">
              <span className="orio-title-text start-logo">{process.env.REACT_APP_TITLE}</span>
            </Col>
          </Row>
          <br></br>
          <div align="center">
            <a href={`${ENV.SERVER_LOGIN_API}/auth/login`}><Button color="primary" className="orio-btn pill login-button">LOGIN</Button></a>
            <br></br><br></br>
          </div>
        </CardBody>
        <Modal show={this.state.error} onHide={this.modelClose} centered>
          <Modal.Header >
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div align='center'>
              <span> Your Account Has Been Disabled </span>
            </div>
            {/* <div align='center'>
              <h3>Warning</h3>
            </div>
            <div>

              <span> You don't have the permission to use Account Management service . </span><br></br>
              <span> Please notify your administrator . </span><br></br>
              <span> If you want to delete your account,click the button below. </span><br></br>
              <span> We will send an email to notify your administrator to delete your account . </span><br></br><br></br>

            </div>
            <div align='center'>
              <Button color="light" className="orio-btn-cancel" onClick={e => { this.modelClose() }}>
                Cancel</Button>&nbsp;&nbsp;
              <Button color="primary" className="orio-btn" onClick={e => { console.log('delete') }}>
                Send Mail to Admin</Button>
            </div> */}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}



export default Start;
