const initialState = {
  createDevice: false,
  editDevice: false,
  openDetail: false,
  deviceId: '',
  deviceName: '',
  zoom: 9,
  center: [25.040193, 121.501923],
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_MAP_OBJECT':
      return Object.assign({}, state, { [action.key]: action.value });
    case 'UPDATE_MAP_CENTER':
      return Object.assign({}, state, { center: action.value });
    case 'CHANGE_MAP_CENTER_ZOOM':
      return Object.assign({}, state, { center: action.value.center, zoom: action.value.zoom });
    case 'CHANG_STATION_SHOW_STATUS':
      return Object.assign({}, state, {
        station: Object.assign({}, state.station, {
          [action.value.stationNum]: action.value.show
        })
      });
    default:
      return state;
  }
}

export default mapReducer