const initialState = {
    active: false,
};

const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'START_LOADING_OVERLAY':
            return Object.assign({}, state, { active: true });
        case 'STOP_LOADING_OVERLAY':
            return initialState;
        default:
            return state;
    }
}

export default loadingReducer