import React, { Component } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { history } from './helpers/history';
import Dashboard from './components/dashboard';
import './App.scss';
import Start from './pages/start';

class App extends Component {

  render() {
    return (
      <div className="App">
        <Router history={history}>
          <div>
            <Switch>
              <Route exact path="/" component={Start} />
              <Route exact path="/error/:loginStatus" component={Start} />
              {/* <Route path="/controller/info" component={Dashboard} /> */}
              <Route component={Dashboard} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
