/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import {
  FormGroup,
  Row,
  Col,
  Label,
  Input,
  // Button
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { thingActions } from '../../../../actions/thing'
import { AppSwitch } from '@coreui/react'


import { connect } from 'react-redux';
class EditIoDoChannel extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
    }
    this.onChange = this.onChange.bind(this);
    this.onChangeModbus = this.onChangeModbus.bind(this);
  }
  onChange(key, value) {
    this.props.thingAction.updateEditChannelData(key, value)
  }
  onChangeModbus(key, value) {
    this.props.thingAction.updateEditIoChannelModbusData(key, value)
  }


  render() {
    const enable = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'enable' });
    const invert = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'inverse' });
    // const powerOnValueEnable = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'powerOnValueEnable' });
    // const powerOnSafeValue = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'powerOnSafeValue' });
    const safeValueEnable = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'safeValueEnable' });
    const safeValue = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'safeValue' });
    // const reportingInterval = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'reportingInterval' });
    const pulseLowTime = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'pulseLowTime' });
    const pulseHighTime = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'pulseHighTime' });
    const pulseCycles = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'pulseCycles' });
    const mode = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'mode' });
    return (
      <Row>
        <Col>
          <FormGroup row>
            <Col md="4">
              <Label>Name</Label>
              <br></br>
              <Input
                type="text"
                name="alias"
                value={this.props.edit.data.alias}
                onChange={e => { this.onChange('alias', e.target.value) }} />
            </Col>
            <Col md="4">
              <Label>Enable</Label>
              <br></br>
              <AppSwitch
                className={'mx-1'}
                size="sm"
                checked={(!!enable.value)}
                onChange={e => { this.onChangeModbus('enable', +(e.target.checked)) }}
                label />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="8" className="orio-device-text">
              <h4>Pre-processing</h4>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="1">
              <Label>Invert</Label>
              <br></br>
              <AppSwitch
                className={'mx-1'}
                size="sm"
                checked={(!!invert.value)}
                disabled={(!(!!enable.value))}
                onChange={e => { this.onChangeModbus('inverse', +(e.target.checked)) }} label />
            </Col>
            <Col md="2">
              <Label>Mode</Label>
              <br></br>
              <Input
                type="select"
                value={mode.value}
                disabled={(!(!!enable.value))}
                onChange={e => { this.onChangeModbus('mode', parseInt(e.target.value, 10)) }}
              >
                <option value={0}>Normal</option>
                <option value={1}>Pulse</option>
              </Input>
            </Col>
            <Col md="3">
              <Label>Pulse high time(ms)</Label>
              <br></br>
              <Input
                type="number"
                name="pulseHighTime"
                value={pulseHighTime.value}
                disabled={(!(!!enable.value))}
                onChange={e => { this.onChangeModbus('pulseHighTime', parseInt(e.target.value, 10)) }}
              />
            </Col>
            <Col md="3">
              <Label>Pulse low time(ms)</Label>
              <br></br>
              <Input
                type="number"
                name="pulseLowTime"
                value={pulseLowTime.value}
                disabled={(!(!!enable.value))}
                onChange={e => { this.onChangeModbus('pulseLowTime', parseInt(e.target.value, 10)) }}
              />
            </Col>
            <Col md="3">
              <Label>Pulse cycles(times)</Label>
              <br></br>
              <Input
                type="number"
                name="pulseCycles"
                value={pulseCycles.value}
                disabled={(!(!!enable.value))}
                onChange={e => { this.onChangeModbus('pulseCycles', parseInt(e.target.value, 10)) }}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="3">
              <Label>Power on safe value</Label>
              <br></br>
              <AppSwitch
                className={'mx-1'}
                size="sm"
                checked={(!!safeValueEnable.value)}
                disabled={(!(!!enable.value))}
                onChange={e => { this.onChangeModbus('safeValueEnable', +(e.target.checked)) }} label />
            </Col>
            <Col md="2">
              <Label>Safe value</Label>
              <br></br>
              <Input
                type="select"
                value={safeValue.value}
                disabled={(!(!!safeValueEnable.value && !!enable.value))}
                onChange={e => { this.onChangeModbus('safeValue', parseInt(e.target.value, 10)) }}
              >
                <option value={0}>Low</option>
                <option value={1}>High</option>
              </Input>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}


function mapStateToProps(state) {
  return {
    thing: state.thing.thingData.info,
    channels: state.thing.thingData.channels,
    edit: state.thing.editIoChannel,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    thingAction: bindActionCreators(thingActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditIoDoChannel);