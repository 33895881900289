/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import {
  Card,
  CardBody,
  FormGroup,
  Row,
  Col,
  Button,
  Label,
  Input
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { thingActions } from '../../../actions/thing'

import { connect } from 'react-redux';
class EditGwChannel extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
    }
    this.cancel = this.cancel.bind(this);
    this.submit = this.submit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(key, value) {
    this.props.thingAction.updateEditGwChannelData(key, value)
  }
  cancel() {
    this.props.thingAction.updateEditGwChannelAction('open', false)
    this.props.thingAction.updateEditGwChannelAction('channelId', '')
    this.props.thingAction.updateEditGwChannelAction('type', '')
    this.props.thingAction.updateEditGwChannelAction('data', {
      alias: '',
      unit: '',
    })
  }
  submit() {
    const submitObject = {
      channel: {
        id: this.props.edit.channelId,
        alias: this.props.edit.data.alias,
        unit: this.props.edit.data.unit
      }
    }
    this.props.thingAction.thingUpdateGatewayChannelData(submitObject, this.props.thing.id, this.props.viewSerialPort)
    this.props.thingAction.updateEditGwChannelAction('open', false)
    this.props.thingAction.updateEditGwChannelAction('channelId', '')
    this.props.thingAction.updateEditGwChannelAction('type', '')
    this.props.thingAction.updateEditGwChannelAction('data', {
      alias: '',
      unit: '',
    })
  }
  render() {

    return (
      <Card className="gray-card">
        <CardBody>
          <FormGroup row>
            <Col md="4">
              <Label>Name</Label>
              <br></br>
              <Input type="text" name="alias" value={this.props.edit.data.alias || ''} onChange={e => { this.onChange('alias', e.target.value) }}></Input>
            </Col>
            {(this.props.edit.type === 'AI' || this.props.edit.type === 'AO') &&
              <Col md="4">
                <Label>Unit</Label>
                <br></br>
                <Input type="text" name="alias" value={this.props.edit.data.unit || ''} onChange={e => { this.onChange('unit', e.target.value) }}></Input>
              </Col>
            }
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md="6">
              </Col>
              <Col md="6">
                <div align="right">
                  <Button color="light" className="orio-btn-cancel" onClick={e => { this.cancel() }} >
                    Cancel</Button>
                  <span>{' '}</span>
                  <Button color="primary" className="orio-btn" onClick={e => { this.submit() }} >
                    Save</Button>
                </div>
              </Col>
            </Row>
          </FormGroup>
        </CardBody>
      </Card>
    );
  }
}


function mapStateToProps(state) {
  return {
    thing: state.thing.thingData.info,
    channels: state.thing.thingData.channels,
    edit: state.thing.editGwChannel,
    viewSerialPort: state.thing.viewSerialPort
  }
}

function mapDispatchToProps(dispatch) {
  return {
    thingAction: bindActionCreators(thingActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditGwChannel);