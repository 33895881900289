import statusClose from './status-close.png'
import statusNull from './status-null.png'
import statusError from './status-error.png'
import statusNormal from './status-normal.png'
import oringLogo from './logo.jpg'


export const images = {
    statusClose,
    statusNull,
    statusError,
    statusNormal
}

export const logo = {
    oringLogo
}