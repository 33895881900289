import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { thingActions } from '../../../actions/thing'
import { Button, Modal } from 'react-bootstrap'
import {
  Col,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';

class UploadModbusConfig extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);

    this.state = {
    };
    this.handleUploadConfigModelClose = this.handleUploadConfigModelClose.bind(this);
    this.confirmUpload = this.confirmUpload.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.submitConfig = this.submitConfig.bind(this);
  }
  handleUploadConfigModelClose() {
    this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'uploadConfig', false)
    this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'tmpConfig', [])
  }
  confirmUpload() {
    this.handleUploadConfigModelClose()
    this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'uploadConfig', false)
    // this.props.thingAction.updateThingAction('editInfo', false)
  }
  uploadFile(event) {
    if (event.target.files.length !== 0) {
      const formdata = new FormData()
      let type = ''
      switch (this.props.viewModbus) {
        case 'io-config':
          type = 'io'
          break;
        case 'gateway-config':
          type = 'gw'
          this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'uploadConfig', false)
          break;
        default:
          break;
      }
      formdata.append('config-csv', event.target.files[0])
      this.props.thingAction.thingUploadConfigFile(type, this.props.viewSerialPort, formdata)
    }
  }
  submitConfig() {
    const mqttPayload = []
    this.props.tmpConfig.map((config) => {
      mqttPayload.push({
        id: config.modbusAddress || '',
        value: parseFloat(config.commandValue, 10),
      })
      return config
    })
    const submitData = {
      thing: {
        id: this.props.thing.thingData.info.id,
        secret: this.props.thing.thingData.info.secret
      },
      payload: mqttPayload
    }
    this.props.thingAction.thingApplyConfigByCsv(submitData)
    this.handleUploadConfigModelClose()
  }
  render() {
    let title = ''
    if (this.props.viewModbus === 'io-config') {
      title = 'I/O'
    } else {
      title = 'gateway'
    }
    const configData = this.props.tmpConfig.map((config, idx) => {
      return <FormGroup row key={idx}>
        <Col md="3">{config.modbusAddress}</Col>
        <Col md="3">{config.alias}</Col>
        <Col md="3">{config.unit}</Col>
        <Col md="3">{config.reportValue}</Col>
      </FormGroup>
    })
    function verify(type, tmpConfig) {
      let verify = true;
      if (tmpConfig.length === 0) {
        verify = false
      }
      switch (type) {
        case 'io-config':
          tmpConfig.map((config) => {
            const address = config.modbusAddress || ''
            const findResult = address.search('IO')
            if (findResult === -1) { verify = false }
            return config
          })
          break;
        case 'gateway-config':
          break;
        default:
      }

      return verify
    }
    const toDisplay = verify(this.props.viewModbus, this.props.tmpConfig)
    return (
      <div>
        <Modal
          size="lg"
          show={this.props.thing.uploadConfig}
          onHide={this.handleUploadConfigModelClose}
          centered>
          <br></br>

          <Modal.Body>
            <FormGroup row>
              <Col md="12">
                <div align="center">
                  <h3>{`Choose a ${title} configuration (.csv).`}</h3>
                </div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3"></Col>
              <Col md="6">
                <div align="center">
                  <Label>
                    <Input
                      type="file"
                      name="csvFile"
                      className="input-file-style"
                      onChange={e => { this.uploadFile(e) }} accept=".csv"
                    />
                    <i className="fa fa-cloud-upload fa-lg mt-4"></i>
                    &nbsp;&nbsp;&nbsp;{`Browse`}
                  </Label>
                </div>
              </Col>
            </FormGroup>
            <br></br>
            {(toDisplay && (this.props.tmpConfig.length > 0) && (this.props.viewModbus === 'io-config')) &&
              <div align="center">
                <FormGroup row >
                  <Col md="3" className="f-weight600"><h4>{'Address'}</h4></Col>
                  <Col md="3" className="f-weight600"><h4>{'Name'}</h4></Col>
                  <Col md="3" className="f-weight600"><h4>{'Unit'}</h4></Col>
                  <Col md="3" className="f-weight600"><h4>{'Expected'}</h4></Col>
                </FormGroup>
                <hr></hr>
                <div className="overflow-scrolling-modbus-config gray-card">
                  {configData}
                </div>
              </div>

            }
            <FormGroup row>
              <Col md="12">
                <div align="center">
                  <Button variant="light" className="orio-btn-cancel pill" onClick={this.handleUploadConfigModelClose}>
                    Cancel</Button>&nbsp;&nbsp;
                    {(toDisplay) &&
                    <Button variant="primary" className="orio-btn pill" onClick={e => { this.submitConfig() }}>
                      Apply</Button>
                  }
                </div>
              </Col>
            </FormGroup>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    thing: state.thing,
    viewModbus: state.thing.viewModbus,
    viewSerialPort: state.thing.viewSerialPort,
    tmpConfig: state.thing.tmpConfig,
  }

}

function mapDispatchToProps(dispatch) {
  return {
    thingAction: bindActionCreators(thingActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadModbusConfig);