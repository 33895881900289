import { ENV } from '../helpers/env'
import { shareFunction } from './shareFunction'
import { thingActions } from './thing'

export const createDeviceActions = {
  updateReducer,
  submitCreateDevice
}

function updateReducer(type, key, value) { return { type, key, value }; }

function submitCreateDevice(data) {
  return dispatch => {
    dispatch(shareFunction.startLoading())
    fetch(`${ENV.SERVER_API}/thing/create-thing`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      return response.json();
    }).then((msg) => {
      if (msg.status < 400) {
        shareFunction.notify('success', 'Action Success')
        dispatch(shareFunction.stopLoading())
        dispatch(thingActions.getThing())

      } else {
        console.log(msg)
        shareFunction.notify('error', 'Action Failed')
        dispatch(updateReducer('UPDATE_ERROR_MESSAGE_OBJECT', 'modelOpen', true))
        dispatch(updateReducer('UPDATE_ERROR_MESSAGE_OBJECT', 'message', msg.message))
        dispatch(shareFunction.stopLoading())
      }
    }).catch((error) => {
      dispatch(shareFunction.stopLoading())
      shareFunction.notify('error', error)
      console.log(error)
    })
  }
}