const initialState = {
  modelOpen: false,
  message: '',
};

const errorMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_ERROR_MESSAGE_OBJECT':
      return Object.assign({}, state, { [action.key]: action.value });
    case 'CLEAR_ERROR_MESSAGE_DEVICE':
      return initialState;
    default:
      return state;
  }
}

export default errorMessageReducer