import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class notFound extends Component {

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="animated fadeIn">
        <h2> 404 Not Found </h2>
        <Link to="/devices">Home</Link>
      </div>
    );
  }
}



export default notFound;
