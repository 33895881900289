/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Button, Col, Card, CardHeader, CardBody, FormGroup, Collapse, Label } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import thingHelper from './variable'
import StatusIcon from '../../../components/statusIcon'
import { thingActions } from '../../../actions/thing'
import { AppSwitch } from '@coreui/react'
import EditGwChannel from './editGwChannels'
import '@zendeskgarden/react-checkboxes/dist/styles.css';
import InputConmmand from './inputCommand'
import { viewChartActions } from '../../../actions/viewChart'
const tableType = thingHelper.gwTableType

class GatewayChannelsTable extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {}
    this.openEdit = this.openEdit.bind(this)
    this.sendCommand = this.sendCommand.bind(this)
    this.openChart = this.openChart.bind(this)
  }
  openEdit(channel) {
    const editChannelData = {
      alias: channel.alias,
      unit: channel.unit
    }
    this.props.thingAction.updateEditGwChannelAction('open', true)
    this.props.thingAction.updateEditGwChannelAction('channelId', channel.id)
    this.props.thingAction.updateEditGwChannelAction('type', channel.type)
    this.props.thingAction.updateEditGwChannelAction('data', editChannelData)
  }
  openChart(channel) {
    if (channel.id !== this.props.viewChart.singleChart.id && !this.props.hideInfo) {
      this.props.viewChartAction.updateChartData('id', channel.id)
      this.props.viewChartAction.updateChartData('alias', channel.alias)
      this.props.viewChartAction.updateChartData('chart', {
        label: [],
        data: [],
      })
      this.props.viewChartAction.getChartRecord(this.props.thing.info.id, `gw-${this.props.viewSerialPort}-report`, { id: channel.id })
    }
  }
  sendCommand(channelId, commandValue, retain) {
    if (retain) {
      console.log('sendRetainCommand')
      this.props.thingAction.sendRetainCommand({
        thing: {
          id: this.props.thing.info.id,
          // secret: this.props.thing.info.secret,
        },
        topic: `gw-${this.props.viewSerialPort}-channel`,
        command: [{
          id: channelId,
          value: commandValue
        }]
      })
    } else {
      let topic = '';
      switch (this.props.viewModbus) {
        case 'io-config':
          topic = 'io-command'
          break;
        case 'gateway-config':
          topic = `gw-${this.props.viewSerialPort}-command`
          break;
        default:
          topic = 'io-command'
          break;
      }
      this.props.thingAction.sendCommand({
        thing: {
          id: this.props.thing.info.id,
          // secret: this.props.thing.info.secret,
        },
        topic,
        command: [{
          id: channelId,
          value: commandValue
        }]
      })
    }
  }
  componentWillUnmount() {
    this.props.viewChartAction.closeChart()
  }
  render() {
    let cardColumn = {
      nameMd: '3',
      idMd: '3',
      statusMd: '3',
      commandMd: '3'
    }
    if (this.props.hideInfo) {
      cardColumn = {
        nameMd: '2',
        idMd: '2',
        statusMd: '4',
        commandMd: '3'
      }
    } else {
      cardColumn = {
        nameMd: '3',
        idMd: '3',
        statusMd: '3',
        commandMd: '3'
      }
    }
    const modbusCard = this.props.thing.gwChannels.map((modbus, idx0) => {
      const channelsCard = tableType.map((item, idx) => {
        const finded = modbus.channels.findIndex((data) => { return data.type === item.type })
        if (finded !== -1) {
          return <Card key={idx}>
            <CardHeader>
              <strong>{item.type}</strong>
            </CardHeader>
            <CardBody>
              <FormGroup row>
                <Col md={cardColumn.nameMd}>Name</Col>
                <Col md={cardColumn.idMd}>ID</Col>
                <Col md={cardColumn.statusMd}>Status</Col>
                <Col md={cardColumn.commandMd}>
                  {(item.type !== 'DI' && item.type !== 'AI') && <Label>Command</Label>}
                </Col>
                {(this.props.hideInfo) &&
                  <Col md="1"></Col>
                }
              </FormGroup>
              <hr></hr>
              {modbus.channels.map((channel, idx2) => {
                if (channel.type === item.type) {
                  let errorBackground = ''
                  let hoverGray = ''
                  if (!(this.props.edit.channelId === channel.id)) {
                    hoverGray = 'hover-gray'
                  }
                  return <div key={idx2} className={`orio-col ${hoverGray} ${errorBackground}`}>
                    <FormGroup row onClick={e => { this.openChart(channel) }}>
                      <Col md={cardColumn.nameMd} className="name-col f-weight600">
                        <Label>{channel.alias}</Label>
                      </Col>
                      <Col md={cardColumn.idMd} className="orio-device-text"><Label>{channel.id}</Label></Col>
                      <Col md={cardColumn.statusMd}>
                        {(item.boolean) &&
                          <StatusIcon value={channel.value}></StatusIcon>
                        }
                        {(!item.boolean) &&
                          <Label>{`${channel.value} ${channel.unit || ''}`}</Label>
                        }
                      </Col>
                      <Col md={cardColumn.commandMd}>
                        <div>
                          {(item.boolean && item.normalCommand) &&
                            <AppSwitch
                              className={'mx-1'}
                              variant={'pill'}
                              size="sm"
                              checked={(!!(channel.command) || false)}
                              onChange={e => { this.sendCommand(channel.id, +(e.target.checked), channel.retain) }}
                              label />
                          }
                          {(!item.boolean && item.normalCommand) &&
                            <InputConmmand
                              channelId={channel.id}
                              commandValue={channel.command || 0}
                              retain={channel.retain}
                            />
                          }
                        </div>
                      </Col>
                      {(this.props.hideInfo) &&
                        <Col md="1">
                          <Button hidden={(this.props.edit.channelId === channel.id)}
                            color="link" className="orio-link-normal py-0 hideden-button" onClick={e => { this.openEdit(channel) }}>
                            <i className="fa fa-pencil"></i>
                          </Button>
                        </Col>
                      }
                      <Col md="12">
                        <Collapse isOpen={(this.props.edit.open && this.props.edit.channelId === channel.id)}>
                          <EditGwChannel />
                        </Collapse>
                      </Col>
                    </FormGroup>
                    <hr></hr>
                  </div>
                }
                return
              })
              }
            </CardBody>
          </Card>
        }
      })
      return <div key={idx0}>
        <h3 className="f-weight800"><Label>{modbus.name}</Label></h3>
        <br></br>
        {channelsCard}
      </div>
    })

    return (
      <div className="overflow-scrolling">
        <br></br>
        {modbusCard}
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    user: state.user.info,
    thing: state.thing.thingData,
    hideInfo: state.thing.action.hideInfo,
    edit: state.thing.editGwChannel,
    viewModbus: state.thing.viewModbus,
    viewSerialPort: state.thing.viewSerialPort,
    viewChart: state.viewChart
  }
}

function mapDispatchToProps(dispatch) {
  return {
    thingAction: bindActionCreators(thingActions, dispatch),
    viewChartAction: bindActionCreators(viewChartActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GatewayChannelsTable);