/* eslint-disable no-this-before-super */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import {
  Button
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { thingActions } from '../actions/thing'
class RestoreButton extends Component {

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  render() {
    return (
      <div className="restore-btn-space-left">
        {(this.props.hideInfo && !this.props.channelEdit && !this.props.editGwChannel && !this.props.editGwConfig) &&
          <div className="restore-col-style-left">
            <Button
              size="sm"
              color="primary"
              className="orio-btn restore-btn-styl-left"
              onClick={event => { this.props.action.thingRestore() }}>
              <i className="fa fa-chevron-left "></i>
            </Button>
          </div>
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    hideInfo: state.thing.action.hideInfo,
    channelEdit: state.thing.editIoChannel.open,
    page: state.location.page,
    editGwChannel: state.thing.editGwChannel.open,
    editGwConfig: state.thing.action.editGwConfig,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(thingActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RestoreButton);