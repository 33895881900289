import React, { Component } from 'react';
import SimpleMap from './simpleMap'
// import PortTable from './portTable'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { mapActions } from '../../actions/map'
// import { controllerActions } from '../../actions/controller'
import {
  Col,
  Row,
} from 'reactstrap';
class map extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      deviceId: ''
    }
    this.findDeviceData = this.findDeviceData.bind(this)
    this.props.action.updatePage('map')
  }
  findDeviceData(thingId) {
    const findResult = this.props.thingList.find((thing) => { return thing.id === thingId });
    if (findResult) {
      this.props.action.updateMapObject('openDetail', true)
      this.props.action.updateMapObject('deviceId', findResult.id)
      this.props.action.updateMapObject('deviceName', findResult.name)
      this.props.action.updateMapObject('center', [findResult.coordinates[1], findResult.coordinates[0]])
    }
  }
  componentDidUpdate() {
    if (this.props.match.params.thingId) {
      if (this.state.deviceId !== this.props.match.params.thingId) {
        this.setState({ deviceId: this.props.match.params.thingId })
        this.findDeviceData(this.props.match.params.thingId)
      }
    } else {
      if (this.state.deviceId) {
        this.setState({ deviceId: null })
        this.props.action.updateMapObject('openDetail', false)
        this.props.action.updateMapObject('deviceId', '')
        this.props.action.updateMapObject('deviceName', '')
      }

    }
  }
  componentWillUnmount() {
    this.props.action.updateMapObject('openDetail', false)
    this.props.action.updateMapObject('deviceId', '')
    this.props.action.updateMapObject('deviceName', '')
    this.props.action.updateMapObject('center', [25.040193, 121.501923])
  }
  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col md={12}>
            <SimpleMap></SimpleMap>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    thingList: state.thing.things,
    map: state.map,
  }
}


function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(mapActions, dispatch),
    // controllerAction: bindActionCreators(controllerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(map);