/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Row, Col, Button, Label, Input } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import AddChannel from '../../../components/addChannel';
import { thingActions } from '../../../actions/thing'
import { ENV } from '../../../helpers/env'
class ThingHeader extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {}
    this.getGwConfig = this.getGwConfig.bind(this)
    this.selectSerialPort = this.selectSerialPort.bind(this)
    this.downloadModbusConfig = this.downloadModbusConfig.bind(this)
    this.uploadModbusConfig = this.uploadModbusConfig.bind(this)
  }
  getGwConfig(thingId, serialPort) {
    this.props.thingAction.updateThingAction('editGwConfig', true)
    this.props.thingAction.updateThingAction('hideInfo', true)
    this.props.thingAction.getThingGatewayModbusConfig(thingId, serialPort)
  }
  selectSerialPort(serialPort) {
    this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'viewSerialPort', serialPort)
    this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'gwConfigCover', false)
    this.props.thingAction.getThingGatewayChannels(this.props.thing.id, serialPort)
    this.props.thingAction.getThingGatewayModbusConfig(this.props.thing.id, serialPort)
  }
  downloadModbusConfig() {
    switch (this.props.viewModbus) {
      case 'io-config':
        window.location.href = `${ENV.SERVER_API}/thing/download-modbus-config/${this.props.thing.id}/io/0`
        break;
      case 'gateway-config':
        window.location.href = `${ENV.SERVER_API}/thing/download-modbus-config/${this.props.thing.id}/gw/${this.props.viewSerialPort}`
        break;
      default:
    }
  }
  uploadModbusConfig() {
    this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'uploadConfig', true)
    this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'tmpConfig', [])
  }

  render() {
    // const thingModel = this.props.thing.labels.find((label) => { return label.key === 'thingModel' }) || { value: '' }
    // const serialNumbers = this.props.model.find((model) => { return model.modelName === thingModel.value }) || { gatewaySerialNum: 1 }

    const serialOptions = []
    for (let i = 0; i < 8; i += 1) {
      serialOptions.push(<option value={i + 1} key={i}>{i + 1}</option>)
    }
    let headerMd = 7
    let functionMd = 3
    if (this.props.hideInfo) {
      headerMd = 5
      functionMd = 5
    } else {
      headerMd = 7
      functionMd = 3
    }

    return (
      <Row>
        <Col md={headerMd}>
          <div align="left">
            <h2 className="orio-title-text">{this.props.thing.name}</h2>
          </div>
        </Col>
        <Col md={2}>
          {(this.props.viewModbus === 'gateway-config') &&
            <Label>
              <Input
                type="select"
                value={this.props.viewSerialPort}
                onChange={e => { this.selectSerialPort(parseInt(e.target.value, 10)) }}
              >
                {serialOptions}
              </Input>
            </Label>
          }
        </Col>
        <Col md={functionMd}>

          {/* <Button
            size="lg"
            color="primary"
            className="orio-btn"
            onClick={e => { window.print() }} >
            Print
          </Button> */}
          {(this.props.viewModbus === 'gateway-config' && !(this.props.editGwConfig)) &&
            <Button
              size="lg"
              color="primary"
              className="orio-btn"
              onClick={e => { this.getGwConfig(this.props.thing.id, this.props.viewSerialPort) }} >
              Configure
            </Button>
          }&nbsp;
            {(this.props.hideInfo && ((this.props.editGwConfig) || (this.props.viewModbus === 'io-config'))) &&
            <Button
              size="lg"
              color="secondary"
              className="btn"
              onClick={e => { this.uploadModbusConfig() }} >
              Upload Config
            </Button>
          }&nbsp;
          {(this.props.hideInfo && !(this.props.editGwConfig)) &&
            <Button
              size="lg"
              color="secondary"
              className="btn"
              onClick={e => { this.downloadModbusConfig() }} >
              Download Config
            </Button>
          }
        </Col>

      </Row>

    );
  }
}


function mapStateToProps(state) {
  return {
    user: state.user.info,
    thing: state.thing.thingData.info,
    model: state.thing.thingModels,
    hideInfo: state.thing.action.hideInfo,
    editGwConfig: state.thing.action.editGwConfig,
    viewModbus: state.thing.viewModbus,
    viewSerialPort: state.thing.viewSerialPort
  }
}

function mapDispatchToProps(dispatch) {
  return {
    thingAction: bindActionCreators(thingActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThingHeader);