import Noty from 'noty'
import '../../node_modules/noty/lib/noty.css'
import '../../node_modules/noty/lib/themes/bootstrap-v4.css'


export const shareFunction = {
  sidebarFix,
  updatePage,
  startLoading,
  stopLoading,
  notify,
  changeRwd,
  sidebarConfig,
  changeHeaderUrl
}



function sidebarFix(display) { return { type: "UPDATE_SIDEBAR_SHOW", value: display }; }
function updatePage(page) { return { type: "UPDATE_LOCATION_PAGE", value: page }; }
function startLoading() { return { type: "START_LOADING_OVERLAY" }; }
function stopLoading() { return { type: "STOP_LOADING_OVERLAY" }; }


function notify(notify_type, notify_text) {
  new Noty({
    type: notify_type,
    theme: 'bootstrap-v4',
    layout: 'topRight',
    text: notify_text,
    timeout: 1000
  }).show();
}
function changeRwd(value) {
  return { type: "UPDATE_RWD_TYPE", value };
}
function sidebarConfig(page, display) {
  return dispatch => {
    switch (page) {
      case 'thing':
        if (display === '') {
          dispatch(shareFunction.sidebarFix('lg'))
        } else {
          dispatch(shareFunction.sidebarFix(''))
        }
        break;

      case 'map':
        if (display === '') {
          dispatch(shareFunction.sidebarFix('lg'))
        } else {
          dispatch(shareFunction.sidebarFix(''))
        }
        break;

      case 'template':
        if (display === '') {
          dispatch(shareFunction.sidebarFix('lg2'))
        } else {
          dispatch(shareFunction.sidebarFix(''))
        }
        break;

      case 'history':
        dispatch(shareFunction.sidebarFix(''))
        break;

      case 'rule':
        dispatch(shareFunction.sidebarFix(''))
        break;

      default:
        break;
    }

  }
}

function changeHeaderUrl(value) {
  return { type: 'CHANGE_HEADER_URL', value }
}