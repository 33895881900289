/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { thingActions } from '../../../actions/thing'
import { Modal } from 'react-bootstrap'
import {
  Button,
  Col,
  Row,
  Input,
  Label
} from 'reactstrap';

class EditImage extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);

    this.state = {
      images: []
    };
    this.handleEditImageModelClose = this.handleEditImageModelClose.bind(this);
    this.confirmUpdate = this.confirmUpdate.bind(this);
    this.remove = this.remove.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }
  handleEditImageModelClose() {
    this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'editImage', false)
    this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'tmpImage', [])
  }
  confirmUpdate() {
    const formdata = new FormData()

    this.props.thing.tmpImage.map((image) => {
      return formdata.append('image', image.file)
    })
    this.handleEditImageModelClose()
    this.props.thingAction.thingUploadImage(this.props.thing.thingData.info.id, formdata)
    this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'editImage', false)
    this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'tmpImage', [])
    this.props.thingAction.updateThingAction('editInfo', false)
  }
  uploadFile(files) {
    if (files.length !== 0) {
      const tmpImages = this.props.thing.tmpImage.map((image) => { return image })
      for (let i = 0; i < files.length; i += 1) {
        tmpImages.push({ file: files[i], url: URL.createObjectURL(files[i]) })
      }
      this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'tmpImage', tmpImages)
    }

  }
  remove(index) {
    const tmpImages = this.props.thing.tmpImage.map((image) => { return image })
    tmpImages.splice(index, 1)
    this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'tmpImage', tmpImages)
  }
  render() {
    return (
      <div>
        <Modal show={this.props.thing.editImage} onHide={this.handleEditImageModelClose} centered>
          <br></br>
          <div align="center">
            <h3><Label className="f-weight800">Edit Image</Label></h3>
          </div>
          <Modal.Body>
            <Row>
              <Col md="12">
                {this.props.thing.tmpImage.map((image, idx) => {
                  return <div align="center" key={idx}>
                    <Row >
                      <Col md="3">
                      </Col>
                      <Col md="4">
                        <img width="150" height="150" src={image.url} />
                      </Col>
                      <Col md="1">
                        <Button size="lg" color="link" className="orio-link-danger restore-col-style" onClick={e => { this.remove(idx) }} >
                          <i className="fa fa-trash"></i>
                        </Button>
                      </Col>
                    </Row>
                    <br></br>
                  </div>
                })}
                <br></br>
                {(this.props.thing.tmpImage.length < 4) &&
                  <Row>
                    <Col align="center" md="3"></Col>
                    <Col align="center" md="6">
                      <Label>
                        <Input
                          type="file"
                          name="imageFile"
                          className="input-file-style"
                          onChange={e => { this.uploadFile(e.target.files) }} accept=".jpg , .png , .jpeg"
                        />
                        <i className="fa fa-cloud-upload fa-lg mt-4"></i>
                        &nbsp;&nbsp;&nbsp;{`Browse`}
                      </Label>
                    </Col>
                  </Row>
                }
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col md="12">
                <div align="center">
                  <Button variant="light" className="orio-btn-cancel pill" onClick={this.handleEditImageModelClose}>
                    Cancel
                  </Button>&nbsp;&nbsp;
                  <Button variant="primary" className="orio-btn pill" onClick={e => { this.confirmUpdate() }}>
                    Upload
                  </Button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    thing: state.thing,
  }

}

function mapDispatchToProps(dispatch) {
  return {
    thingAction: bindActionCreators(thingActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditImage);