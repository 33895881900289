import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { thingActions } from '../../../actions/thing'
import { Button, Modal } from 'react-bootstrap'
import {
  Col,
  Row,
  Label,
} from 'reactstrap';

class DeleteThing extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);

    this.state = {
    };
    this.handleDeleteThingModelClose = this.handleDeleteThingModelClose.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);

  }
  handleDeleteThingModelClose() {
    this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'deleteThing', false)
  }
  confirmDelete() {
    this.handleDeleteThingModelClose()
    this.props.thingAction.deleteThing(this.props.thing.thingData.info.id)
    this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'deleteThing', false)
    this.props.thingAction.updateThingAction('editInfo', false)
  }
  render() {
    return (
      <div>
        <Modal show={this.props.thing.deleteThing} onHide={this.handleDeleteThingModelClose} centered>
          <br></br>

          <Modal.Body>
            <Row>
              <Col>
                <Label align="center">
                  <h3>Are you sure you want to delete this Thing? This action cannot be undone.</h3>
                </Label>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div align="center">
                  <Button variant="light" className="orio-btn-cancel pill" onClick={this.handleDeleteThingModelClose}>
                    Cancel</Button>&nbsp;&nbsp;
                  <Button variant="primary" className="orio-btn pill" onClick={e => { this.confirmDelete() }}>
                    Confirm</Button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    thing: state.thing,
  }

}

function mapDispatchToProps(dispatch) {
  return {
    thingAction: bindActionCreators(thingActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteThing);