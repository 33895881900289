import React, { Component } from 'react';
import { Button, CardBody, Col, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { thingActions } from '../../actions/thing'
import { shareFunction } from '../../actions/shareFunction'
// import Draggable from 'react-draggable';
class ThingHomePage extends Component {

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      intervalId: '',
      popoverOpen: false
    };
    this.props.commomAction.updatePage('devices')
    this.props.thingAction.initinalData()
    this.props.thingAction.thingRestore()
  }

  componentWillMount() { }


  render() {
    return (
      <div className="animated fadeIn margin-top-30" align="center">
        <Col lg={12}>

          <CardBody>
            <Row>
              <Col md="12"><h2 className="orio-title-text">Select a device from the left list</h2></Col>
            </Row>
            <Row>
              <Col md="12"><h2 className="orio-title-text">or</h2></Col>
            </Row>
            <Row>
              <Col md="12">
                <h2 className="orio-title-text">
                  <span>Add a new device by clicking</span>
                  <span className='margin-left-10'></span>
                  <Button color="light" size="lg" className="btn-pill" disabled>
                    <i className="fa fa-plus "></i>
                  </Button>
                  <span className='margin-left-10'>.</span>
                </h2>
              </Col>
            </Row>
          </CardBody>

        </Col>
        {/* <Draggable
          handle=".handle"
          defaultPosition={{ x: 0, y: 0 }}
          grid={[25, 25]}
          scale={1}
          onStart={this.handleStart}
          onStop={e => { console.log({ x: e.x, y: e.y }) }}>
          <div>
            <div className="handle">Drag from here</div>
            <div>This readme is really dragging on...</div>
          </div>
        </Draggable> */}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    thingAction: bindActionCreators(thingActions, dispatch),
    commomAction: bindActionCreators(shareFunction, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThingHomePage);  