/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import {
  FormGroup,
  Row,
  Col,
  Label,
  Input,
  Button
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { thingActions } from '../../../../actions/thing'
import { AppSwitch } from '@coreui/react'



import { connect } from 'react-redux';
class EditIoDiChannel extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
    }
    this.onChange = this.onChange.bind(this);
    this.onChangeModbus = this.onChangeModbus.bind(this);
    this.sendCommand = this.sendCommand.bind(this)
  }
  onChange(key, value) {
    this.props.thingAction.updateEditChannelData(key, value)
  }
  onChangeModbus(key, value) {
    this.props.thingAction.updateEditIoChannelModbusData(key, value)
  }
  sendCommand(channelId, commandValue) {
    let topic = '';
    switch (this.props.viewModbus) {
      case 'io-config':
        topic = 'io-command'
        break;
      case 'gateway-config':
        topic = `gw-${this.props.viewSerialPort}-command`
        break;
      default:
        topic = 'io-command'
        break;
    }
    this.props.thingAction.sendCommand({
      thing: {
        id: this.props.thing.id,
        // secret: this.props.thing.secret,
      },
      topic,
      command: [{
        id: channelId,
        value: commandValue
      }]
    })
  }
  render() {
    const enable = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'enable' });
    const invert = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'inverse' });
    const filter = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'filter' });
    const reportingInterval = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'reportingInterval' });
    const trigger = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'trigger' });
    const triggerEvent = this.props.edit.data.modbusSetting.find((data) => { return data.alias === 'triggerEvent' });
    const clear = this.props.channels.find((data) => { return data.id === this.props.edit.channelId }) || { clearCount: '' }
    return (
      <Row>
        <Col>
          <FormGroup row>
            <Col md="4">
              <Label>Name</Label>
              <br></br>
              <Input
                type="text"
                name="alias"
                value={this.props.edit.data.alias}
                onChange={e => { this.onChange('alias', e.target.value) }}
              />
            </Col>
            <Col md="2">
              <Label>Enable</Label>
              <br></br>
              <AppSwitch
                className={'mx-1'}
                size="sm"
                checked={(!!enable.value)}
                onChange={e => { this.onChangeModbus('enable', +(e.target.checked)) }}
                label />
            </Col>
            <Col md="4">
              <br></br>
              <Button color="primary" className="orio-btn" onClick={e => { this.sendCommand(clear.clearCount, +(true)) }} >
                CLEAR COUNTS</Button>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="8" className="orio-device-text">
              <h4>Pre-processing</h4>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="4">
              <Label>Invert</Label>
              <br></br>
              <AppSwitch
                className={'mx-1'}
                size="sm"
                checked={(!!invert.value)}
                disabled={(!(!!enable.value))}
                onChange={e => { this.onChangeModbus('inverse', +(e.target.checked)) }} label />
            </Col>
            <Col md="4">
              <Label>Filter time(ms)</Label>
              <br></br>
              <Input
                type="number"
                name="filter"
                value={filter.value}
                disabled={(!(!!enable.value))}
                onChange={e => { this.onChangeModbus('filter', parseInt(e.target.value, 10) || 0) }}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="8" className="orio-device-text">
              <h4>Reporting</h4>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="4">
              <Label>Reporting interval(ms)</Label>
              <br></br>
              <Input
                type="number"
                name="reportingInterval"
                value={reportingInterval.value}
                disabled={(!(!!enable.value))}
                onChange={e => { this.onChangeModbus('reportingInterval', parseInt(e.target.value, 10) || 0) }}
              />
            </Col>
            <Col md="2">
              <Label>Trigger</Label>
              <br></br>
              <AppSwitch
                className={'mx-1'}
                size="sm"
                checked={(!!trigger.value)}
                disabled={(!(!!enable.value))}
                label
                onChange={e => { this.onChangeModbus('trigger', +(e.target.checked)) }} />
            </Col>
            <Col md="3">
              <Label>Trigger event</Label>
              <br></br>
              <Input
                type="select"
                value={triggerEvent.value}
                disabled={(!(!!trigger.value && !!enable.value))}
                onChange={e => { this.onChangeModbus('triggerEvent', parseInt(e.target.value, 10) || 0) }}
              >
                <option value={0}>Rising</option>
                <option value={1}>Falling</option>
                <option value={2}>Both</option>
              </Input>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}


function mapStateToProps(state) {
  return {
    thing: state.thing.thingData.info,
    channels: state.thing.thingData.channels,
    edit: state.thing.editIoChannel,
    viewModbus: state.thing.viewModbus,
    viewSerialPort: state.thing.viewSerialPort
  }
}

function mapDispatchToProps(dispatch) {
  return {
    thingAction: bindActionCreators(thingActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditIoDiChannel);