/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import L from 'leaflet'
import { history } from "../../helpers/history";
import { Button, Row, Col } from 'reactstrap';
import 'leaflet/dist/leaflet.css'
import { Map as LeafletMap, TileLayer, Marker, Popup, Tooltip,/* Rectangle, Polyline, Polygon*/ } from 'react-leaflet'
import Control from 'react-leaflet-control';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { mapActions } from '../../actions/map'
// import { addDeviceActions } from "../../actions/getThing";
import { createDeviceActions } from "../../actions/createDevice";
// import Draggable from 'react-draggable';
import statusNormal from '../../components/images/status-normal.png'
import statusClose from '../../components/images/status-close.png'
import statusError from '../../components/images/status-error.png'
// import DeviceDetail from './deviceDetail'
const onlineIcon = L.icon({
  iconUrl: statusNormal,
});
const errorIcon = L.icon({
  iconUrl: statusError,
});
const offlineIcon = L.icon({
  iconUrl: statusClose,
});

class SimpleMap extends Component {
  constructor() {
    super()
    this.state = {
      zoom: 9,
      editPopup: false,
      editThingId: '',
      editThingName: '',
      edit_lat: 0,
      edit_lng: 0
    }
    this.changeMarker = this.changeMarker.bind(this)
    this.createDevice = this.createDevice.bind(this)
    this.editPosition = this.editPosition.bind(this)
    this.cancelAction = this.cancelAction.bind(this)
    this.addEditPopup = this.addEditPopup.bind(this)
    this.cancelEdit = this.cancelEdit.bind(this)
    this.startEdit = this.startEdit.bind(this)
  }
  changeMarker(id) {
    if (id) {
      history.push(`/map/${id}/io-config`)
    } else {
      history.push(`/map`)
    }

  }

  createDevice(coordinates) {
    if (this.props.map.createDevice) {
      this.props.createDeviceAction.updateReducer('UPDATE_CREATE_DEVICE_OBJECT', 'modelOpen', true)
      this.props.createDeviceAction.updateReducer('UPDATE_CREATE_DEVICE_INFO_OBJECT', 'coordinate_lat', coordinates.lat)
      this.props.createDeviceAction.updateReducer('UPDATE_CREATE_DEVICE_INFO_OBJECT', 'coordinate_lng', coordinates.lng)

      this.props.action.updateMapObject('createDevice', false)
    }
    if (this.props.map.editDevice) {
      this.props.action.updateMapObject('editDevice', false)
    }
  }
  addEditPopup(coordinates) {
    this.setState({
      editPopup: true,
      edit_lat: coordinates.lat,
      edit_lng: coordinates.lng
    })
    // this.props.action.updateMapObject('editDevice', false)
  }
  cancelEdit(action) {
    this.setState({
      editPopup: false,
      edit_lat: 0,
      edit_lng: 0,
    })
    if (action === 0) {
      this.props.action.updateMapObject('editDevice', false)
    }
  }
  startEdit(thingId, coordinates) {
    this.props.action.updateThingCoordinates(thingId, [coordinates.lng, coordinates.lat])
    this.cancelEdit(0)
  }
  editPosition() {
    this.setState({
      editThingId: this.props.map.deviceId,
      editThingName: this.props.map.deviceName
    })
    this.props.action.updateMapObject('editDevice', true)
  }
  cancelAction() {
    this.props.action.updateMapObject('createDevice', false)
    this.props.action.updateMapObject('editDevice', false)
  }
  componentDidUpdate() {
    // console.log(window.innerWidth)
  }
  componentWillUnmount() {
    this.props.action.updateMapObject('createDevice', false)
    this.props.action.updateMapObject('editDevice', false)
  }
  render() {
    let deviceList = []
    if (this.props.device.search) {
      deviceList = this.props.device.searchList
    } else {
      deviceList = this.props.device.things
    }

    const position = this.props.map.center;

    function checkStatus(map) {
      if (map.createDevice) {
        return 'mapAddDevice'
      }
      if (map.editDevice) {
        return 'mapAddDevice'
      }
      return ''
    }

    function createPopup(map, changeMarker, editPosition, user) {

      return <Popup
        onClose={e => { changeMarker(null) }}
        position={map.center}>
        <div >
          <a href={`../../devices/${map.deviceId}/io-config`}>{`${map.deviceName}`}</a>
          <br></br>
          <span>{`[${map.center}]`}</span>
        </div>
        <Button color="link" className="orio-link-normal relocate-marker" size="sm" onClick={e => { editPosition() }}>
          <i className="fa fa-map-marker fa-lg mt-4"></i>&nbsp;
          relocate
                </Button>
      </Popup>
    }

    function printMarker(deviceList, map, changeMarker) {
      const marker = deviceList.map((item, idx) => {
        let icon = offlineIcon
        let opacityDefault = 0

        if (item.id === map.deviceId) {
          opacityDefault = 0
        } else {
          opacityDefault = 1
        }

        if (item.status.online) {
          icon = onlineIcon
          if (item.status.forcing) {
            icon = errorIcon
          }
          if (item.status.abnormal) {
            icon = errorIcon
          }
        }
        return <Marker
          key={idx}
          position={[item.coordinates[1], item.coordinates[0]]}
          icon={icon}
          onClick={e => { changeMarker(item.id) }}
        >
          {(opacityDefault === 1) &&
            <Tooltip
              opacity={0.7}
              permanent={true}
              direction={`top`}
              className="rectangle">
              {item.name}
            </Tooltip>
          }
        </Marker>
      })
      return marker
    }
    function addEditPopup(thingId, thingName, lat, lng, cancelEdit, startEdit) {
      return <Popup
        onClose={e => { cancelEdit(1) }}
        position={[lat, lng]}>
        <div align="center">
          <h5>{`${thingName}`}</h5>
          <span>{`New Location`}</span>
          <br></br><br></br>
          <span>{`[${Math.round(lat * 1000000) / 1000000},${Math.round(lng * 1000000) / 1000000}]`}</span>
        </div>
        <br></br>
        <Row>
          <Col md="6">
            <div align="left">
              <Button color="light" className="orio-btn-cancel" size="sm" onClick={e => { cancelEdit(0) }}>
                cancel
                </Button>
            </div>
          </Col>
          <Col md="6">
            <div align="right">
              <Button color="primary" className="orio-btn" size="sm" onClick={e => { startEdit(thingId, { lat, lng }) }}>
                save
                </Button>
            </div>
          </Col>
        </Row>
      </Popup>
    }
    function checkAction(coordinates, action, createDevice, addEditPopup) {
      // console.log(coordinates)
      if (action.editDevice) {
        // console.log(coordinates)
        addEditPopup(coordinates)
        return
      }
      if (action.createDevice) {
        createDevice(coordinates)
        return
      }

    }

    // const rectangle = [
    //   [25.344154651765056, 120.95397949218751],
    //   [24.387402487118187, 122.13226318359376],
    // ]
    // const polyline = [
    //   [24.051638048188412, 120.75622558593751],
    //   [23.669897983698597, 120.44860839843751],
    //   [23.352607360313208, 120.39367675781251],
    //   [23.009281805389204, 120.36346435546876],
    //   [22.624847411546625, 120.61340332031251],
    // ]
    // const polygon = [
    //   [23.619785662252827, 121.21765136718751],
    //   [23.946469492145706, 121.82189941406251],
    //   [23.393141066278343, 122.49206542968751],
    //   [22.6855531536986, 122.37670898437501],
    //   [23.039811664242123, 121.4154052734375],
    // ]

    return (
      <div className="orio-saas-map">
        <LeafletMap
          className={`leaflet-container ${checkStatus(this.props.map)}`}
          center={position}
          zoom={this.props.map.zoom}
          zoomControl={false}
          onClick={e => { checkAction(e.latlng, this.props.map, this.createDevice, this.addEditPopup) }}
          onContextMenu={e => { this.cancelAction() }}
        // onZoomend={e => { this.props.action.updateMapObject('zoom', e.target._zoom) }}
        >
          <TileLayer
            // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
            // url='https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'
            maxZoom={19}
            minZoom={0}

          />
          {/* <Polyline color="#1b1c4f" positions={polyline} />
          <Polygon color="#f5a623" positions={polygon} />
          <Rectangle
            bounds={rectangle}
            color="#2c2e82"
          /> */}
          {(!this.props.rwdType) &&
            <Control position='topright'>
              <div className="map-control">
                <h5><img src={statusNormal}></img>Online</h5>
                <h5><img src={statusClose}></img>Offline</h5>
                <h5><img src={statusError}></img>Abnormal</h5>
              </div>
            </Control>
          }

          {/* {(this.props.rwdType) &&
            <Control position='topleft'>
              <div className={`map-control-device-list-rwd-type`}>
                <DeviceDetail />
              </div>
            </Control>
          } */}

          {/* {(this.props.map.openDetail) &&
            <Control position="topright">
              <div className="animated fadeIn map-control-tmp">
                <DeviceDetail />
              </div>

            </Control>
          } */}
          {printMarker(deviceList, this.props.map, this.changeMarker)}
          {(this.props.map.deviceId !== '') &&
            createPopup(this.props.map, this.changeMarker, this.editPosition, this.props.user)
          }
          {(this.state.editPopup) &&
            addEditPopup(this.state.editThingId, this.state.editThingName, this.state.edit_lat, this.state.edit_lng, this.cancelEdit, this.startEdit)
          }
        </LeafletMap>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    map: state.map,
    device: state.thing,
    user: state.user.info,
    rwdType: state.location.rwdType,
  }
}


function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(mapActions, dispatch),
    createDeviceAction: bindActionCreators(createDeviceActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleMap);