const initialState = {
  info: {
    orgId: '',
    name: '',
    role: '',
    permission: {
      read: true,
      command: false,
      write: false
    }
  }
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_USER_OBJECT':
      return Object.assign({}, state, { [action.key]: action.value });
    case 'USER_LOGOUT':
      return state;
    default:
      return state;
  }
}


export default user