import { ENV } from '../helpers/env'
import { shareFunction } from './shareFunction'
import { history } from "../helpers/history";

export const thingActions = {
  getThing,
  updateReducer,
  getThingInfo,
  getThingChannels,
  openCommand,
  openGwCommand,
  updateThingAction,
  updateThing,
  thingHide,
  thingRestore,
  initinalData,
  getThingGatewayChannels,
  getThingGatewayModbusConfig,
  updateEditChannelAction,
  updateEditChannelData,
  updateEditIoChannelModbusData,
  sendCommand,
  sendRetainCommand,
  updateChannelData,
  thingUploadImage,
  thingUploadConfigFile,
  updateDataBySocket,
  updateGwDataBySocket,
  deleteThing,
  editGatewayModbusConfig,
  addCommandCondition,
  thingUpdateGatewayModbusCommand,
  updateEditGwChannelAction,
  updateEditGwChannelData,
  thingUpdateGatewayChannelData,
  thingCoverGatewayChannelData,
  thingApplyConfigByCsv,
  getThingModel,
  updateThingStatus,
}


function updateReducer(type, key, value) { return { type, key, value }; }

function getThing() {
  return dispatch => {
    fetch(`${ENV.SERVER_API}/thing/get-things`, {
      method: 'GET',
      credentials: 'include',
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    }).then((msg) => {
      dispatch(updateReducer('UPDATE_THING_OBJECT', 'things', msg))
    }).catch((error) => {
      shareFunction.notify('error', 'Action Failed')
      console.log(error)
    })
  }

}

function getThingModel() {
  return dispatch => {
    fetch(`${ENV.SERVER_API}/thing-model/get-all-model`, {
      method: 'GET',
      credentials: 'include',
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    }).then((msg) => {
      dispatch(updateReducer('UPDATE_THING_OBJECT', 'thingModels', msg.data))
    }).catch((error) => {
      shareFunction.notify('error', 'Action Failed')
      console.log(error)
    })
  }
}

function getThingInfo(thingId) {
  return dispatch => {
    fetch(`${ENV.SERVER_API}/thing/get-device-data/${thingId}`, {
      method: 'GET',
      credentials: 'include',
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    }).then((msg) => {
      dispatch(updateReducer('UPDATE_THING_INFO_DATA', 'info', msg))
    }).catch((error) => {
      shareFunction.notify('error', 'Action Failed')
      console.log(error)
    })
  }

}

function getThingChannels(thingId) {
  return dispatch => {
    dispatch(shareFunction.startLoading())
    fetch(`${ENV.SERVER_API}/thing/get-device-data-channels/${thingId}`, {
      method: 'GET',
      credentials: 'include',
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    }).then((msg) => {
      const newChannels = msg.channels
      newChannels.map((channel) => {
        return channel.openCommand = false
      })
      dispatch(updateReducer('UPDATE_THING_INFO_DATA', 'channels', newChannels))
      dispatch(updateReducer('UPDATE_THING_INFO_DATA', 'forcing', msg.forcing))
      dispatch(updateReducer('UPDATE_THING_INFO_DATA', 'cjc', msg.cjc))
      dispatch(shareFunction.stopLoading())
    }).catch((error) => {
      dispatch(shareFunction.stopLoading())
      shareFunction.notify('error', 'Action Failed')
      console.log(error)
    })
  }

}

function getThingGatewayChannels(thingId, serialPort) {
  return dispatch => {
    dispatch(shareFunction.startLoading())
    fetch(`${ENV.SERVER_API}/thing/gw-modbus-channels/${thingId}/${serialPort}`, {
      method: 'GET',
      credentials: 'include',
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    }).then((msg) => {
      msg.map((modbus) => {
        modbus.channels.map((channel) => {
          return channel.openCommand = false
        })
        return modbus
      })
      dispatch(updateReducer('UPDATE_THING_INFO_DATA', 'gwChannels', msg))
      dispatch(shareFunction.stopLoading())
    }).catch((error) => {
      dispatch(shareFunction.stopLoading())
      shareFunction.notify('error', 'Serial Port does not registed')
      dispatch(updateReducer('UPDATE_THING_OBJECT', 'viewSerialPort', 1))
      dispatch(getThingGatewayChannels(thingId, 1))
    })
  }

}

function getThingGatewayModbusConfig(thingId, serialPort) {
  return dispatch => {
    dispatch(shareFunction.startLoading())
    fetch(`${ENV.SERVER_API}/thing/gw-modbus-config/${thingId}/${serialPort}`, {
      method: 'GET',
      credentials: 'include',
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    }).then((msg) => {
      msg.modbusConfig.map((modbus) => {
        modbus.index = modbus.name.id.split('_')[1]
        return modbus
      })
      const editModbus = {
        serialPort: {
          action: {
            edit: false
          },
          modbusAddress: msg.serialPort.modbusAddress.map((modbus) => {
            return {
              id: modbus.id,
              alias: modbus.alias,
              value: modbus.value
            }
          })
        },
        modbusConfig: msg.modbusConfig.map((modbusConfig) => {
          return {
            index: modbusConfig.name.id.split('_')[1],
            name: {
              id: modbusConfig.name.id,
              alias: modbusConfig.name.alias,
              edit: false
            },
            retain: {
              id: modbusConfig.retain.id,
              alias: modbusConfig.retain.alias,
              value: modbusConfig.retain.value,
            },
            enable: {
              id: modbusConfig.enable.id,
              alias: modbusConfig.enable.alias,
              value: modbusConfig.enable.value
            },
            config: {
              action: {
                edit: false,
                create: false,
                delete: false
              },
              data: modbusConfig.config.data.map((datas) => {
                return {
                  id: datas.id,
                  alias: datas.alias,
                  value: datas.value
                }
              })
            },
            channels: {
              data: modbusConfig.channels.data.map((datas) => {
                return {
                  id: datas.id,
                  alias: datas.alias,
                  unit: datas.unit,
                  action: {
                    edit: false
                  }
                }
              })
            }
          }
        }),

      }

      dispatch(updateReducer('UPDATE_THING_INFO_DATA', 'gwConfigs', msg))
      dispatch(updateReducer('UPDATE_THING_INFO_DATA', 'gwEditConfigs', editModbus))
      dispatch(shareFunction.stopLoading())
    }).catch((error) => {
      dispatch(shareFunction.stopLoading())
      shareFunction.notify('error', 'Serial Port does not registed')
      dispatch(updateReducer('UPDATE_THING_OBJECT', 'viewSerialPort', 1))
      dispatch(getThingGatewayModbusConfig(thingId, 1))
    })
  }
}

function updateThing(thingId, data) {
  return dispatch => {
    dispatch(shareFunction.startLoading())
    fetch(`${ENV.SERVER_API}/thing/device-update-data/${thingId}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    }).then((msg) => {
      dispatch(updateReducer('UPDATE_THING_INFO_DATA', 'info', msg))
      dispatch(getThing())
      dispatch(shareFunction.stopLoading())
      shareFunction.notify('success', 'Action Success')
    }).catch((error) => {
      dispatch(shareFunction.stopLoading())
      shareFunction.notify('error', 'Action Failed')
      console.log(error)
    })
  }
}

function updateThingAction(key, value) {
  return dispatch => {
    dispatch(updateReducer('UPDATE_THING_ACTION_DATA', key, value))
  }
}

function thingHide() {
  return dispatch => {
    dispatch(updateReducer('UPDATE_THING_ACTION_DATA', 'hideInfo', true))
  }
}

function thingRestore() {
  return dispatch => {
    dispatch(updateReducer('UPDATE_THING_ACTION_DATA', 'hideInfo', false))
  }
}

function initinalData() {
  return { type: 'INITINAL_THING_DATA' };
}

function updateEditChannelAction(key, value) {
  return dispatch => {
    dispatch(updateReducer('UPDATE_EDIT_IO_CHANNEL_OBJECT', key, value))
  }
}

function updateEditGwChannelAction(key, value) {
  return dispatch => {
    dispatch(updateReducer('UPDATE_EDIT_GW_CHANNEL_OBJECT', key, value))
  }
}

function updateEditChannelData(key, value) {
  return dispatch => {
    dispatch(updateReducer('UPDATE_EDIT_CHANNEL_DATA_OBJECT', key, value))
  }
}
function updateEditGwChannelData(key, value) {
  return dispatch => {
    dispatch(updateReducer('UPDATE_EDIT_GATEWAY_CHANNEL_DATA_OBJECT', key, value))
  }
}

function updateEditIoChannelModbusData(key, value) {
  return dispatch => {
    dispatch(updateReducer('UPDATE_EDIT_IO_CHANNEL_MODBUS_OBJECT', key, value))
  }
}

function sendCommand(data) {
  return dispatch => {
    fetch(`${ENV.SERVER_API}/command/send-command`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (response.status < 400) {
        shareFunction.notify('success', 'Action Success')
      } else {
        shareFunction.notify('error', 'Action Failed')
      }
    }).catch((error) => {
      dispatch(shareFunction.stopLoading())
      shareFunction.notify('error', 'Action Failed')
      console.log(error)
    })
  }
}

function sendRetainCommand(data) {
  return dispatch => {
    fetch(`${ENV.SERVER_API}/command/send-retain-config`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (response.status < 400) {
        shareFunction.notify('success', 'Action Success')
      } else {
        shareFunction.notify('error', 'Action Failed')
      }
    }).catch((error) => {
      dispatch(shareFunction.stopLoading())
      shareFunction.notify('error', 'Action Failed')
      console.log(error)
    })
  }
}

function openCommand(channelId, open) {
  return { type: 'THING_OPEN_CHANNEL_COMMAND', key: channelId, value: open };
}

function openGwCommand(channelId, open) {
  return { type: 'THING_OPEN_GATEWAY_CHANNEL_COMMAND', key: channelId, value: open };
}

function updateChannelData(submitObject) {
  return dispatch => {
    dispatch(shareFunction.startLoading())
    fetch(`${ENV.SERVER_API}/thing/modbus-config`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(submitObject),
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    }).then((msg) => {
      dispatch(getThingChannels(submitObject.thing.id))
      // dispatch(shareFunction.stopLoading())
      shareFunction.notify('success', 'Action Success')
    }).catch((error) => {
      dispatch(shareFunction.stopLoading())
      shareFunction.notify('error', 'Action Failed')
      console.log(error)
    })
  }
}

function thingUploadImage(thingId, data) {
  return dispatch => {
    dispatch(shareFunction.startLoading())
    fetch(`${ENV.SERVER_API}/thing/thing-upload-images/${thingId}`, {
      method: 'PATCH',
      credentials: 'include',
      body: data,
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    }).then((msg) => {
      dispatch(updateReducer('UPDATE_THING_INFO_IMAGE', 'images', msg.data))
      dispatch(shareFunction.stopLoading())
      shareFunction.notify('success', 'Action Success')
    }).catch((error) => {
      dispatch(shareFunction.stopLoading())
      shareFunction.notify('error', 'Action Failed')
      console.log(error)
    })
  }
}

function thingUploadConfigFile(type, serialPort, data) {
  return dispatch => {
    fetch(`${ENV.SERVER_API}/thing/thing-upload-modbus-config/${type}/${serialPort}`, {
      method: 'POST',
      credentials: 'include',
      body: data,
    }).then((response) => {
      if (response.status < 400) {
        return response.json();
      }
    }).then((msg) => {
      switch (type) {
        case 'io':
          dispatch(updateReducer('UPDATE_THING_OBJECT', 'tmpConfig', msg))
          break;
        case 'gw':
          msg.modbusConfig.map((modbus) => {
            modbus.index = modbus.name.id.split('_')[1]
            return modbus
          })
          dispatch(updateReducer('COVER_THING_INFO_DATA', 'gwEditConfigs', msg))
          dispatch(updateReducer('UPDATE_THING_OBJECT', 'gwConfigCover', true))
          break;
        default:
          break;
      }
      // shareFunction.notify('success', 'Action Success')
    }).catch((error) => {
      dispatch(updateReducer('UPDATE_THING_OBJECT', 'tmpConfig', []))
      shareFunction.notify('error', 'Action Failed')
      console.log(error)
    })
  }
}

function thingUpdateGatewayChannelData(submitObject, thingId, serialPort) {
  return dispatch => {
    dispatch(shareFunction.startLoading())
    fetch(`${ENV.SERVER_API}/thing/gw-channel-config/${thingId}/${serialPort}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(submitObject),
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    }).then((msg) => {
      dispatch(getThingGatewayChannels(thingId, serialPort))
      shareFunction.notify('success', 'Action Success')
    }).catch((error) => {
      dispatch(shareFunction.stopLoading())
      shareFunction.notify('error', 'Action Failed')
      console.log(error)
    })
  }
}

function thingCoverGatewayChannelData(submitObject, serialPort) {
  return dispatch => {
    dispatch(shareFunction.startLoading())
    fetch(`${ENV.SERVER_API}/thing/thing-cover-gw-modbus-config/${serialPort}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(submitObject),
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    }).then((msg) => {
      dispatch(getThingGatewayChannels(submitObject.thing.id, serialPort))
      dispatch(updateThingAction('editGwConfig', false))
      shareFunction.notify('success', 'Action Success')
    }).catch((error) => {
      dispatch(shareFunction.stopLoading())
      shareFunction.notify('error', 'Action Failed')
      console.log(error)
    })
  }
}

function thingUpdateGatewayModbusCommand(submitObject, serialPort) {
  return dispatch => {
    dispatch(shareFunction.startLoading())
    fetch(`${ENV.SERVER_API}/thing/gw-modbus-config/${submitObject.thing.id}/${serialPort}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(submitObject),
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    }).then((msg) => {
      dispatch(getThingGatewayChannels(submitObject.thing.id, serialPort))
      dispatch(updateThingAction('editGwConfig', false))
      shareFunction.notify('success', 'Action Success')
    }).catch((error) => {
      dispatch(shareFunction.stopLoading())
      shareFunction.notify('error', 'Action Failed')
      console.log(error)
    })
  }
}

function updateDataBySocket(data) {
  return {
    type: 'UPDATE_CHANNEL_DATA_BY_SOCKET',
    data
  }
}

function updateGwDataBySocket(data) {
  return {
    type: 'UPDATE_GW_CHANNEL_DATA_BY_SOCKET',
    data
  }
}

function deleteThing(thingId) {
  return dispatch => {
    dispatch(shareFunction.startLoading())
    fetch(`${ENV.SERVER_API}/thing/delete-thing/${thingId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      if (response.status === 200) {
        history.push('/devices');
        dispatch(getThing())
        dispatch(shareFunction.stopLoading())
        shareFunction.notify('success', 'Action Success')
      } else {
        dispatch(shareFunction.stopLoading())
        shareFunction.notify('error', 'Action Failed')
      }
    }).catch((error) => {
      dispatch(shareFunction.stopLoading())
      shareFunction.notify('error', 'Action Failed')
      console.log(error)
    })
  }
}

function editGatewayModbusConfig(index, editType, channelId, key, value) {
  return {
    type: 'EDIT_GATEWAY_MODBUS_CONFIG',
    index, editType, channelId, key, value
  }
}

function addCommandCondition(config) {
  const newCondition = {
    index: config.index,
    name: {
      id: config.name,
      alias: config.name,
      edit: false
    },
    retain: {
      id: config.retain,
      alias: config.retain,
      value: 0,
    },
    enable: {
      id: config.enable,
      alias: 'enable',
      value: 1,
    },
    config: {
      action: {
        edit: false,
        create: false,
        delete: false
      },
      data: [
        {
          id: config.protocalSelection,
          alias: 'protocalSelection',
          value: 0
        },
        {
          id: config.netId,
          alias: 'netId',
          value: 1
        },
        {
          id: config.functionCode,
          alias: 'functionCode',
          value: 0
        },
        {
          id: config.startAddress,
          alias: 'startAddress',
          value: 0
        },
        {
          id: config.numberOfData,
          alias: 'numberOfData',
          value: 0
        },
        {
          id: config.typeOfData,
          alias: 'typeOfData',
          value: 0
        },
        {
          id: config.scanInterval,
          alias: 'scanInterval',
          value: 10
        },
        {
          id: config.writeDataType,
          alias: 'writeDataType',
          value: 15
        },
        {
          id: config.modbusTcpPort,
          alias: 'modbusTcpPort',
          value: 0
        },
        {
          id: config.modbusTcpIp,
          alias: 'modbusTcpIp',
          value: '0'
        },
      ]
    },
    channels: {
      data: []
    }
  }
  return {
    type: 'CREATE_GATEWAY_MODBUS_COMMAND',
    value: newCondition
  }
}

function thingApplyConfigByCsv(submitData) {
  return dispatch => {
    fetch(`${ENV.SERVER_API}/thing/thing-apply-modbus-config`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(submitData),
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    }).then((msg) => {
      shareFunction.notify('success', 'Action Success')
    }).catch((error) => {
      dispatch(shareFunction.stopLoading())
      shareFunction.notify('error', 'Action Failed')
      console.log(error)
    })
  }
}

function updateThingStatus(thingId, online) {
  return dispatch => {
    dispatch(updateReducer('UPDATE_THINGS_CONNECT_STATUS', thingId, online))
  }
}