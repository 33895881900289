const initialState = {
  page: '',
  device: '',
  sidebarDisplay: true,
  rwdType: false,
  changeHeader: false,
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_LOCATION_PAGE':
      return Object.assign({}, state, { page: action.value });
    case 'UPDATE_LOCATION_DEVICE':
      return Object.assign({}, state, { device: action.value });
    case 'UPDATE_SIDEBAR_SHOW':
      return Object.assign({}, state, { sidebarDisplay: action.value });
    case 'UPDATE_RWD_TYPE':
      return Object.assign({}, state, { rwdType: action.value });
    case 'CHANGE_HEADER_URL':
      return Object.assign({}, state, { changeHeader: action.value });
    default:
      return state;
  }
}

export default locationReducer