/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Button, Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { thingActions } from '../../../actions/thing'
class ThingImage extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 }
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }
  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }
  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.props.thing.images.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }
  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.props.thing.images.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }
  openEditImages() {
    // this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'tmpImage', this.props.thing.images.map((item) => { return { file: {}, url: item } }))
    this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'editImage', true)

  }

  render() {
    const { activeIndex } = this.state;

    return (
      <div>
        <h4 className="orio-title-text">
          Diagram of Mechanism
          <Button color="link" className="orio-link-normal " onClick={e => { this.openEditImages() }}>
            <i className="fa fa-pencil"></i>
          </Button>
        </h4>
        <div align="center">
          <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous}>
            <CarouselIndicators items={this.props.thing.images} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
            {
              this.props.thing.images.map((item, idx) => {
                return (
                  <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={idx}>
                    <img width="350" height="350" src={item} />
                  </CarouselItem>
                );
              })
            }
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
          </Carousel>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    user: state.user.info,
    thing: state.thing.thingData.info,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    thingAction: bindActionCreators(thingActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThingImage);