/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { images } from './images';


class StatusIcon extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: images.statusNull
    }

  }

  render() {
    function check(value) {
      switch (value) {
        case 0:
          return images.statusClose
        case 1:
          return images.statusNormal
        default:
          return images.statusNull
      }
    }

    return (
      <img className="restore-col-style-left" src={check(this.props.value)} width="36" height="36" />
    );
  }
}


export default StatusIcon;