/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import {
  FormGroup,
  Row,
  Card,
  CardBody,
  Col,
  Label,
  Input,
  Button
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { thingActions } from '../../../actions/thing'
import thingHelper from './variable'
import { connect } from 'react-redux';
import { AppSwitch } from '@coreui/react'
class GatewayModbusConfig extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
    }
    this.onChange = this.onChange.bind(this);
    this.editSerialPort = this.editSerialPort.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.editCommandModbus = this.editCommandModbus.bind(this);
    this.editNewChannels = this.editNewChannels.bind(this);
    this.addCondition = this.addCondition.bind(this);
    this.removeCondition = this.removeCondition.bind(this);
    this.submitConfig = this.submitConfig.bind(this);
  }
  onChange(key, value) {
    this.props.thingAction.updateEditChannelData(key, value)
  }
  cancelEdit() {
    this.props.thingAction.updateThingAction('editGwConfig', false)
  }
  editSerialPort(key, value) {
    this.props.thingAction.updateReducer('EDIT_GATEWAY_SERIAL_PORT_CONFIG', key, value)
  }
  submitConfig() {
    const submitObject = {
      thing: {
        id: this.props.thing.id,
        secret: this.props.thing.secret
      },
      serialPort: this.props.gwEditConfigs.serialPort,
      modbusConfig: this.props.gwEditConfigs.modbusConfig
    }
    const channelList = []
    submitObject.modbusConfig.map((modbus) => {
      modbus.channels.data.map((channel) => {
        channelList.push(channel.id)
        return channel
      })
      return modbus
    })
    const verify = channelList.filter((channel, idx, arr) => {
      return arr.indexOf(channel) !== idx
    })
    if (verify.length === 0) {
      if (this.props.gwConfigCover) {
        this.props.thingAction.thingCoverGatewayChannelData(submitObject, this.props.viewSerialPort)
      } else {
        this.props.thingAction.thingUpdateGatewayModbusCommand(submitObject, this.props.viewSerialPort)
      }
    }
  }
  editCommandModbus(index, editType, channelId, key, value) {
    this.props.thingAction.editGatewayModbusConfig(index, editType, channelId, key, value)
    if (key === 'functionCode' && value < 2) {
      this.props.thingAction.editGatewayModbusConfig(index, editType, channelId, 'typeOfData', 0)
    }

    const newCommandModbus = this.props.gwEditConfigs.modbusConfig.find((modbus) => { return modbus.index === index })
    if (newCommandModbus !== undefined) {
      const netId = newCommandModbus.config.data.find((configData) => { return configData.alias === 'netId' }) || { value: 0 }
      const startAddress = newCommandModbus.config.data.find((configData) => { return configData.alias === 'startAddress' }) || { value: 0 }
      const numberOfData = newCommandModbus.config.data.find((configData) => { return configData.alias === 'numberOfData' }) || { value: 0 }
      const functionCode = newCommandModbus.config.data.find((configData) => { return configData.alias === 'functionCode' }) || { value: 0 }
      const dataType = newCommandModbus.config.data.find((configData) => { return configData.alias === 'typeOfData' }) || { value: 0 }
      const newChannels = this.editNewChannels({
        netId: netId.value,
        startAddress: startAddress.value,
        numberOfData: numberOfData.value,
        functionCode: functionCode.value,
        dataType: dataType.value
      }, newCommandModbus.channels.data)
      this.props.thingAction.updateReducer('EDIT_GATEWAY_MODBUS_CHANNELS', index, newChannels)
    }
  }
  editNewChannels(config, channels) {
    const idInterval = thingHelper.dataTypeTable.find((type) => { return type.value === config.dataType }) || { interval: 1 }
    const channelList = [];
    const returnChannels = [];
    const startAddress = `GW${config.netId}_${config.functionCode}`;
    for (let i = 0; i < config.numberOfData; i += 1) {
      channelList.push(`${startAddress}${(Array(4).join('0') + (config.startAddress + i * idInterval.interval).toString(10)).slice(-4)}`);
    }
    channelList.map((channel, idx) => {
      const newChannel = channels[idx] || { id: '', alias: '', unit: '', action: { edit: false } }
      newChannel.id = channel
      if (config.functionCode < 3) {
        newChannel.unit = ''
      }
      returnChannels.push(newChannel)
      return channel
    })
    return returnChannels
  }
  addCondition() {
    const commandList = []
    for (let i = 0; i < 32; i += 1) {
      commandList.push({
        index: 42000 + i,
        used: false
      })
    }
    this.props.gwEditConfigs.modbusConfig.map((command) => {
      const usedIndex = commandList.findIndex((data) => { return data.index === parseInt(command.index, 10) })
      commandList[usedIndex].used = true
      return command
    })
    this.props.gwEditConfigs.modbusConfig.map((command) => {
      if (command.config.action.delete) {
        const usedIndex = commandList.findIndex((data) => { return data.index === parseInt(command.index, 10) })
        commandList[usedIndex].used = false
      }
      return command
    })
    const findAddIndex = commandList.findIndex((data) => { return data.used === false })
    if (findAddIndex !== -1) {
      const addConditionIndex = commandList[findAddIndex].index
      let enableAddress = 42632
      if (42032 - addConditionIndex <= 16) {
        enableAddress = enableAddress + 1
      }
      this.props.thingAction.addCommandCondition({
        index: `${addConditionIndex}`,
        name: `NAME_${addConditionIndex}`,
        retain: `RETAIN_${addConditionIndex}`,
        protocalSelection: `GW_${addConditionIndex - 100}`,
        netId: `GW_${addConditionIndex}`,
        functionCode: `GW_${addConditionIndex + 100}`,
        startAddress: `GW_${addConditionIndex + 200}`,
        numberOfData: `GW_${addConditionIndex + 300}`,
        typeOfData: `GW_${addConditionIndex + 400}`,
        scanInterval: `GW_${addConditionIndex + 500}`,
        writeDataType: `GW_${addConditionIndex + 600}`,
        modbusTcpPort: `GW_${addConditionIndex + 700}`,
        modbusTcpIp: `GW_${(42800) + (addConditionIndex - 42000) * 32}`,
        enable: `GW_${enableAddress}-${(addConditionIndex % 42000) % 16}`,
      })
    }
  }
  removeCondition(index) {

    //DELETE_GATEWAY_MODBUS_COMMAND
    this.props.thingAction.updateReducer('DELETE_GATEWAY_MODBUS_COMMAND', index, true)
  }
  componentWillUnmount() {
    this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'gwConfigCover', false)
  }
  render() {
    const serialPortConfig = thingHelper.gatewaySerialPortConfig.map((config, idx) => {
      const serialPortEditConfig = this.props.gwEditConfigs.serialPort.modbusAddress.find((serialItems) => { return serialItems.alias === config.alias }) || { alias: 'none', value: 0 }
      switch (config.type) {
        case 'number':
          return <Col md="2" key={idx}>
            <Label>{config.title}</Label>
            <br></br>
            <Input
              type="number"
              value={serialPortEditConfig.value}
              onChange={e => { this.editSerialPort(serialPortEditConfig.alias, parseFloat(e.target.value, 10) || 0) }}
            />
          </Col>
        case 'select':
          const configOptions = config.options.map((option, idx5) => {
            return <option value={option.value} key={idx5}>{option.text}</option>
          })
          return <Col md="2" key={idx}>
            <Label>{config.title}</Label>
            <br></br>
            <Input
              type={config.type}
              value={serialPortEditConfig.value}
              onChange={e => { this.editSerialPort(serialPortEditConfig.alias, parseFloat(e.target.value, 10)) }}
            >
              {configOptions}
            </Input>
          </Col>
        default:
          return config;
      }

    })
    const allCommandModbus = this.props.gwEditConfigs.modbusConfig.map((gwModbusConfig, idx2) => {
      if (!gwModbusConfig.config.action.delete) {
        const commandEnable = gwModbusConfig.enable
        const commandRetain = gwModbusConfig.retain || { value: 0 }
        const commandIndex = gwModbusConfig.index
        const commandName = gwModbusConfig.name
        const functionCodeValue = gwModbusConfig.config.data.find((modbus) => { return modbus.alias === 'functionCode' }) || { alias: 'none', value: 0 }
        const commandModbus = thingHelper.gatewayCommandModbusConfig.map((commandConfig, idx3) => {
          const commandValue = gwModbusConfig.config.data.find((modbus) => { return modbus.alias === commandConfig.alias }) || { alias: 'none', value: 0 }
          const protocalSelectionValue = gwModbusConfig.config.data.find((modbus) => { return modbus.alias === 'protocalSelection' }) || { alias: 'none', value: 0 }
          switch (commandConfig.type) {
            case 'number':
              return <Col md="2" key={idx3}>
                <Label>{commandConfig.title}</Label>
                <br></br>
                <Input
                  type={commandConfig.type}
                  value={commandValue.value}
                  disabled={(commandValue.alias === 'modbusTcpPort') && (protocalSelectionValue.value < 1)}
                  min={commandConfig.min || 0}
                  max={commandConfig.max || 9999}
                  onChange={e => {
                    this.editCommandModbus(commandIndex, 'config', '', commandValue.alias, parseInt(e.target.value, 10))
                  }}
                />
              </Col>
            case 'select':
              const configOptions = commandConfig.options.map((option, idx5) => {
                return <option value={option.value} key={idx5}>{option.text}</option>
              })
              // const functionCodeValue = gwModbusConfig.config.data.find((modbus) => { return modbus.alias === 'functionCode' }) || { alias: 'none', value: 0 }
              return <Col md="2" key={idx3}>
                <Label>{commandConfig.title}</Label>
                <br></br>
                <Input
                  type={commandConfig.type}
                  value={commandValue.value}
                  disabled={(commandValue.alias === 'typeOfData') && (functionCodeValue.value < 2)}
                  onChange={e => {
                    this.editCommandModbus(commandIndex, 'config', '', commandValue.alias, parseInt(e.target.value, 10))
                  }}
                >
                  {configOptions}
                </Input>
              </Col>
            case 'text':
              return <Col md="3" key={idx3}>
                <Label>{commandConfig.title}</Label>
                <br></br>
                <Input
                  type={commandConfig.type}
                  value={commandValue.value}
                  disabled={(commandValue.alias === 'modbusTcpIp') && (protocalSelectionValue.value < 1)}
                  onChange={e => {
                    this.editCommandModbus(commandIndex, 'config', '', commandValue.alias, e.target.value)
                  }}
                />
              </Col>
            default:
              return commandConfig;
          }


        })
        const commandChannels = gwModbusConfig.channels.data.map((channel, idx4) => {
          if (!channel.alias) { channel.alias = channel.id }
          return <FormGroup row key={idx4}>
            <Col md="2">
              <Label>Sensor ID</Label>
              <br></br>
              <Label className="orio-device-text">{channel.id}</Label>
            </Col>
            <Col md="4">
              <Label>Name</Label>
              <br></br>
              <Input
                type="text"
                value={channel.alias}
                onChange={e => {
                  this.props.thingAction.editGatewayModbusConfig(commandIndex, 'channels', channel.id, 'alias', e.target.value)
                }} />
            </Col>
            {(parseInt(channel.id.split('_')[1], 10) > 20000) &&
              <Col md="2">
                <Label>Unit</Label>
                <br></br>
                <Input
                  type="text"
                  value={channel.unit}
                  onChange={e => {
                    this.props.thingAction.editGatewayModbusConfig(commandIndex, 'channels', channel.id, 'unit', e.target.value)
                  }} />
              </Col>
            }
          </FormGroup>
        })
        return <div key={idx2}>
          <FormGroup row >
            <Col md="3">
              <Label>Name</Label>
              <br></br>
              <Input
                type="text"
                value={commandName.alias}
                onChange={e => {
                  this.props.thingAction.editGatewayModbusConfig(commandIndex, 'name', '', '', e.target.value)
                }} />
            </Col>
            <Col md="2">
              <Label>Enable</Label>
              <br></br>
              <AppSwitch
                className={'mx-1'}
                size="sm"
                checked={(!!commandEnable.value)}
                onChange={e => {
                  this.props.thingAction.editGatewayModbusConfig(commandIndex, 'enable', '', '', +(e.target.checked))
                }}
                label />
            </Col>
            {(functionCodeValue.value !== 0 && functionCodeValue.value !== 4) &&
              <Col md="2"></Col>
            }
            {(functionCodeValue.value === 0 || functionCodeValue.value === 4) &&
              <Col md="2">
                <Label>Retain</Label>
                <br></br>
                <AppSwitch
                  className={'mx-1'}
                  size="sm"
                  checked={(!!commandRetain.value)}
                  onChange={e => {
                    this.props.thingAction.editGatewayModbusConfig(commandIndex, 'retain', '', '', +(e.target.checked))
                  }}
                  label />
              </Col>
            }
            <Col md="4"></Col>
            <Col md="1">
              <Button color="link" className="orio-link-danger" onClick={e => { this.removeCondition(commandIndex) }} >
                <i className="fa fa-trash"></i>{` Delete`}
              </Button>
            </Col>
          </FormGroup>
          <FormGroup row >
            {commandModbus}
          </FormGroup>
          <Card className="gray-card">
            <CardBody>
              {commandChannels}
            </CardBody>
          </Card>
        </div>
      }
    })
    return (
      <Row>
        <Col>
          <FormGroup row>
            <Col md="12">
              <h2 className="f-weight800"><Label>Modbus RTU</Label></h2>
            </Col>
          </FormGroup>
          <FormGroup row>
            {serialPortConfig}
          </FormGroup>
          <FormGroup row>
            <Col md="12">
              <h2 className="f-weight800"><Label>Modbus Slave</Label></h2>
            </Col>
          </FormGroup>
          <div className="overflow-scrolling-modbus-config">
            {allCommandModbus}
          </div>
          <hr></hr>
          <FormGroup row>
            <Col md="10">
              <Button
                size="lg"
                color="secondary"
                className="btn"
                onClick={e => { this.addCondition() }} >
                Add Configuration
            </Button>
            </Col>
            <Col md="1">
              <Button
                size="lg"
                color="secondary"
                className="btn"
                onClick={e => { this.cancelEdit() }} >
                Cancel
            </Button>
            </Col>
            <Col md="1">
              <Button
                size="lg"
                color="primary"
                className="orio-btn"
                onClick={e => { this.submitConfig() }} >
                {`Apply `}
              </Button>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}


function mapStateToProps(state) {
  return {
    thing: state.thing.thingData.info,
    gwEditConfigs: state.thing.thingData.gwEditConfigs,
    viewSerialPort: state.thing.viewSerialPort,
    gwConfigCover: state.thing.gwConfigCover
  }
}

function mapDispatchToProps(dispatch) {
  return {
    thingAction: bindActionCreators(thingActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GatewayModbusConfig);