/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import {
  Button,
  Col,
  FormGroup,
  Label,
  Row,
  Input,
} from 'reactstrap';
import thingHelper from './variable'
import { thingActions } from '../../../actions/thing'

const showDetail = thingHelper.deviceDetail
class ThingInformation extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      thingId: '',
      name: '',
      sn: '',
      coordinates_lng: 0,
      coordinates_lat: 0,
      description: ''
    }
    this.submitInfo = this.submitInfo.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.dataSync = this.dataSync.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate() {
    if (this.state.thingId !== this.props.thing.id) {
      this.setState({ thingId: this.props.thing.id })
      this.props.thingAction.updateThingAction('editInfo', false)
      this.dataSync()
    }
  }
  dataSync() {
    this.setState({
      name: this.props.thing.name,
      sn: this.props.thing.sn,
      coordinates_lng: this.props.thing.coordinates[1],
      coordinates_lat: this.props.thing.coordinates[0],
      description: this.props.thing.description,
    })
  }
  submitInfo() {
    this.props.thingAction.updateThingAction('editInfo', false)
    const submit = {
      name: this.state.name,
      sn: this.state.sn,
      description: this.state.description,
      coordinates: [
        parseFloat(this.state.coordinates_lat, 10),
        parseFloat(this.state.coordinates_lng, 10),
      ]
    }
    this.props.thingAction.updateThing(this.props.thing.id, submit)
  }
  cancelEdit() {
    this.props.thingAction.updateThingAction('editInfo', false)
    this.dataSync()
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    return (
      <div>
        <h3>
          <Row>
            <Col md="9">
              <Label className="f-weight800">Device Information</Label>

              <Button color="link" className="orio-link-normal " onClick={event => {
                this.props.thingAction.updateThingAction('editInfo', true)
              }}>
                <i className="fa fa-pencil"></i>
              </Button>
            </Col>
            {(this.props.action.editInfo) &&
              <Col md="3">
                <Button color="link" className="orio-link-danger" onClick={event => { this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'deleteThing', true) }}>
                  <i className="fa fa-trash"></i>{` Delete the Device`}
                </Button>
              </Col>
            }
          </Row>
        </h3>
        <h4 className="orio-title-text">Details</h4>
        <div>
          {(!this.props.action.editInfo) &&
            <FormGroup>
              {
                showDetail.map((item, idx) => {
                  if (item.key === 'coordinates') {
                    return <Row key={idx}>
                      <Col md="4">
                        <span>{item.title} : </span>
                      </Col>
                      <Col md="8">
                        <span>[{this.props.thing.coordinates[1]} , {this.props.thing.coordinates[0]}]</span>
                      </Col>
                    </Row>
                  }
                  return <Row key={idx}>
                    <Col md="4">
                      <span>{item.title} : </span>
                    </Col>
                    <Col md="8">
                      <span>{this.props.thing[item.key]} </span>
                    </Col>
                  </Row>
                })
              }
            </FormGroup>
          }
          {(this.props.action.editInfo) &&
            <FormGroup>
              {
                showDetail.map((item, idx) => {
                  if (item.key === 'coordinates') {
                    return <Row key={idx}>
                      <Col md="4"><span>{item.title} : </span></Col>
                      <Col md="4"><Input type="number" name="coordinates_lng" max="180" min="-180" value={this.state.coordinates_lng} onChange={e => { this.onChange(e) }}></Input></Col>
                      <Col md="4"><Input type="number" name="coordinates_lat" max="90" min="-90" value={this.state.coordinates_lat} onChange={e => { this.onChange(e) }}></Input></Col>
                    </Row>
                  }
                  if (item.edit) {
                    return <Row key={idx}>
                      <Col md="4"><span>{item.title} : </span></Col>
                      <Col md="8">
                        <Input type={item.type} name={item.key} value={this.state[item.key]} onChange={e => { this.onChange(e) }}></Input></Col>
                    </Row>
                  }
                  return <Row key={idx}>
                    <Col md="4">
                      <span>{item.title} : </span>
                    </Col>
                    <Col md="8">
                      <span>{this.props.thing[item.key]} </span>
                    </Col>
                  </Row>
                })
              }
              <br></br>
              <Row>
                <Col md="12">
                  <div align="center">
                    <Button color="light" className="orio-btn-cancel" onClick={e => { this.cancelEdit() }}>Cancel</Button>
                    &nbsp;&nbsp;
                  <Button color="primary" className="orio-btn" onClick={e => { this.submitInfo() }}>Save</Button>
                  </div>
                </Col>
              </Row>
            </FormGroup>
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    thing: state.thing.thingData.info,
    action: state.thing.action
  }
}

function mapDispatchToProps(dispatch) {
  return {
    thingAction: bindActionCreators(thingActions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ThingInformation);  