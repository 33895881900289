/* eslint-disable no-this-before-super */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component, Suspense } from 'react';
import {
  Row,
  Input,
  Col,
  Button
} from 'reactstrap';
import { Link } from 'react-router-dom';
import CreateThing from './addDevice';
import {
  AppSidebar,
  AppSidebarNav,
  AppSidebarFooter,
  AppSidebarHeader,
} from '@coreui/react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { thingActions } from "../actions/thing";
import { images } from './images'



class DeviceSideBar extends Component {

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      searchWord: '',
    }
    this.updateSearchWord = this.updateSearchWord.bind(this)
    this.startSearch = this.startSearch.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
  }
  updateSearchWord(value) {
    // eslint-disable-next-line no-regex-spaces
    const pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
    if (pattern.test(value)) {
      value = ''
    }

    this.setState({ searchWord: value })
  }
  startSearch(key) {
    if (key === 'Enter') {
      if (this.state.searchWord === '') {
        return
      }
      let searchList = []
      for (let i = 0; i < this.props.thing.things.length; i += 1) {
        if (this.props.thing.things[i].name.search(this.state.searchWord) !== -1 || this.props.thing.things[i].id.search(this.state.searchWord) !== -1) {
          searchList.push(this.props.thing.things[i])
        }
      }
      this.props.action.updateReducer('UPDATE_THING_OBJECT', 'search', true)
      this.props.action.updateReducer('UPDATE_THING_OBJECT', 'searchList', searchList)
    }
    return
  }
  // componentDidMount() {
  //   window.addEventListener("resize", this.resize.bind(this));
  // }
  // resize() {
  //   if (window.innerWidth < 1120 && !this.props.rwdType) {
  //     console.log('change rwd')
  //   }
  //   if (window.innerWidth > 1120 && this.props.rwdType) {
  //     console.log('retun normal')
  //   }
  // }
  clearSearch() {
    this.props.action.updateReducer('UPDATE_THING_OBJECT', 'search', false)
    this.setState({ searchWord: '' })
  }


  render() {

    const sidebarSearchList = this.props.thing.searchList.map((item, idx) => {
      let image = images.statusClose
      if (item.status.online) { image = images.statusNormal }
      if (item.status.abnormal) { image = images.statusError }
      return <li className="nav-item" key={idx}>
        <Link className="nav-link" to={`/${this.props.location.page}/${item.id}/io-config`}>
          <img height="20px" width="20px" src={image}></img>&nbsp;
                    {item.name}
        </Link>
      </li>
    })

    const sidebarList = this.props.thing.things.map((item, idx) => {
      let image = images.statusClose
      if (item.status.online) { image = images.statusNormal }
      if (item.status.online && item.status.abnormal) { image = images.statusError }
      return <li className="nav-item" key={idx}>
        <Link className="nav-link" to={`/${this.props.location.page}/${item.id}/io-config`}>
          <img height="20px" width="20px" src={image}></img>&nbsp;
                    {item.name}
        </Link>
      </li>
    })

    return (
      <AppSidebar display='lg' className="orio-sidebar">
        <AppSidebarHeader>
          {(!this.props.rwdType) &&
            <Row className="device-search-form">
              <Col md="9">
                <Input className="device-search"
                  value={this.state.searchWord}
                  onChange={e => { this.updateSearchWord(e.target.value) }}
                  onKeyDown={e => { this.startSearch(e.key) }} placeholder="Search"
                />
              </Col>
              <Button className="device-search-btn" onClick={e => { this.startSearch('Enter') }}>
                <i className="fa fa-search"></i>
              </Button>
            </Row>
          }
        </AppSidebarHeader>
        <Suspense>
          {(this.props.thing.search) &&

            <div className="crollbar-container overflow-scrolling-sidebar">
              <Col>
                <Button color="link" className="orio-link-back" onClick={e => { this.clearSearch() }}>
                  <span>{'<<'}</span>&nbsp;&nbsp;&nbsp;<span>{'back'}</span>
                </Button>
              </Col>
              {(sidebarSearchList.length === 0 && !this.props.rwdType) &&
                <div align="center" className="top30">
                  <h4>
                    <span>No Data</span>
                  </h4>
                </div>
              }
              {(sidebarSearchList.length !== 0 && !this.props.rwdType) &&
                <AppSidebarNav>
                  <ul className="nav ">
                    {sidebarSearchList}
                  </ul>
                </AppSidebarNav>
              }
            </div>
          }
          {(!this.props.thing.search && !this.props.rwdType) &&
            <AppSidebarNav>
              <div className=" overflow-scrolling-sidebar">
                <ul className="nav ">
                  {sidebarList}
                </ul>
              </div>
            </AppSidebarNav>

          }
        </Suspense>
        <AppSidebarFooter>
          {(!this.props.rwdType) &&
            <CreateThing></CreateThing>
          }
        </AppSidebarFooter>
      </AppSidebar>
    )
  }
}

function mapStateToProps(state) {
  return {
    thing: state.thing,
    location: state.location,
    rwdType: state.location.rwdType
  }
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(thingActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSideBar);