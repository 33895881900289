/* eslint-disable jsx-a11y/alt-text */
import React, { Component, Suspense } from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import {
  Container,
} from 'reactstrap';
import {
  notFound,
  thingHomePage,
  thingInfo,
  map
} from '../pages'
import RestoreButton from './restoreButton';
import DeviceSideBar from './sidebar';
import { history } from '../helpers/history';
import { thingActions } from "../actions/thing";
import {
  AppHeader,
} from '@coreui/react';
import ErrorMessage from './errorMessage';
import socket from '../helpers/socket'

const DefaultHeader = React.lazy(() => import('./header'));

class Dashboard extends Component {

  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      intervalId: '',
      socketStart: false,
      deviceList: []
    }
    // this.props.action.getThing()
  }
  componentWillMount() {
    socket.on('DEVICE_CONNECT_STATUS', message => {
      this.props.action.updateThingStatus(message.data.id, message.data.online);
      // console.log(message);
    });
  }

  componentWillUnmount() {
    socket.off('DEVICE_CONNECT_STATUS');
  }

  render() {

    return (
      <LoadingOverlay
        active={this.props.loading}
        spinner
        text='Loading ...'
      >
        <div className="app orio-background">

          <div className="app-header">
            <AppHeader fixed className="orio-nav">
              <Suspense fallback={this.loading()}>
                <DefaultHeader />
              </Suspense>
            </AppHeader>
          </div>

          <div className="app-body" >
            {(!this.props.rwdType && this.props.sidebar) &&
              <DeviceSideBar />
            }
            <main className="main">
              <Container className="px-0" fluid >
                <Suspense fallback={this.loading()}>
                  <Router history={history}>
                    <Switch>
                      <Route exact path="/devices" component={thingHomePage} />
                      <Route path="/devices/:thingId/:modbusConfig" component={thingInfo} />
                      <Route exact path="/map" component={map} />
                      <Route path="/map/:thingId/io-config" component={map} />
                      <Route component={notFound} />
                    </Switch>
                  </Router>
                </Suspense>
              </Container>
            </main>
            {(this.props.page === 'devices') &&
              <RestoreButton></RestoreButton>
            }
          </div>
        </div>
        <ErrorMessage />
      </LoadingOverlay>
    );
  }
}
function mapStateToProps(state) {
  return {
    page: state.location.page,
    loading: state.loading.active,
    sidebar: state.location.sidebarDisplay,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(thingActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
