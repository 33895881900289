/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
// import { thingActions } from '../../actions/thing'
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import moment from 'moment'
import {
  Row,
  Col,
  Button,

} from 'reactstrap';
import { viewChartActions } from '../../../actions/viewChart'
class ChannelChart extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {};
    this.changeRealTimeChart = this.changeRealTimeChart.bind(this)
    this.changeYesterdayChart = this.changeYesterdayChart.bind(this)
  }
  changeRealTimeChart() {
    if (!this.props.viewChart.singleChart.realChart) {
      this.props.viewChartAction.changeRealTimeChart()
    }
  }
  changeYesterdayChart() {
    if (this.props.viewChart.singleChart.realChart) {
      this.props.viewChartAction.cancelRealTimeChart()
      switch (this.props.viewModbus) {
        case 'io-config':
          this.props.viewChartAction.getChartRecord(this.props.thing.info.id, 'io-report', { id: this.props.viewChart.singleChart.id })
          break;
        case 'gateway-config':
          this.props.viewChartAction.getChartRecord(this.props.thing.info.id, `gw-${this.props.viewSerialPort}-report`, { id: this.props.viewChart.singleChart.id })
          break;
        default:
      }
      //this.props.viewChartAction.getChartRecord(this.props.thing.info.id, 'io-report', { id: channel.id })
      //this.props.viewChartAction.getChartRecord(this.props.thing.info.id, `gw-${this.props.viewSerialPort}-report`, { id: channel.id })
    }
  }
  componentDidMount() { }
  render() {
    function getRange(chartData) {
      let max = Math.max(...chartData.data)
      let min = Math.min(...chartData.data)
      if (min === 'Infinity') { min = 10 }
      if (max === -'Infinity') { max = 1 }
      // const result = unitTransform(unit)
      const ticks = {
        max,
        min,
        stepSize: (max - min) / 2
      }
      return ticks
    }

    function getLineData(chartData) {
      const lineData = []
      let portData = chartData.chart.data
      const defaultData = {
        label: chartData.id,
        fill: false,
        steppedLine: true,
        yAxisID: 'A',
        backgroundColor: '#f6a000',
        borderColor: '#f6a000',
        pointRadius: 1,
        lineTension: 0.1,
        data: portData,
        spanGaps: true
      }
      lineData.push(defaultData)
      return lineData
    }
    function getMax(data) {
      let max = 'loading'
      max = Math.max(...data).toFixed(2)
      if (max === '-Infinity') {
        max = 'No Data'
      }

      return max
    }
    function getMin(data) {
      let min = Math.min(...data).toFixed(2)
      if (min === 'Infinity') {
        min = 'No Data'
      }

      return min
    }
    return (
      <div className="animated fadeIn">
        <div>
          <Row>
            <Col md="12">
              <h3 className="f-weight800">{this.props.viewChart.singleChart.alias}</h3>
            </Col>
            <Col md="6">
              <Button color="link" className="orio-link-normal" onClick={e => { this.changeYesterdayChart() }}>
                {'Latested Report Record'}
              </Button>
            </Col>
            <Col md="6">
              <Button color="link" className="orio-link-normal" onClick={e => { this.changeRealTimeChart() }}>
                {'Real Time Data'}
              </Button>
            </Col>
            <Col md="12">
              {(this.props.viewChart.singleChart.realChart) &&
                <h4 className="orio-title-text">Real Time Chart</h4>
              }
              {(!this.props.viewChart.singleChart.realChart) &&
                <h4 className="orio-title-text">Latested Report Record</h4>
              }
            </Col>
            <Col md="6">
              <h5>Maximun :<span className="orio-title-text-danger">{getMax(this.props.viewChart.singleChart.chart.data) || 'loading...'}</span> </h5>
            </Col>
            <Col md="6">
              <h5>Minimun : <span className="orio-link-normal">{getMin(this.props.viewChart.singleChart.chart.data) || 'loading...'}</span></h5>
            </Col>
            <Col md="12" className="orio-background">
              <br></br>
              <div className="chart-wrapper">

                <Line
                  data={{
                    labels: this.props.viewChart.singleChart.chart.label.map((timestamp) => {
                      return moment(timestamp).utc().add(new Date().getTimezoneOffset() * -1, 'minutes').format().slice(0, -1)
                    }) || [],
                    datasets: getLineData(this.props.viewChart.singleChart)
                  }}
                  options={{
                    tooltips: {
                      enabled: true,
                      // custom: CustomTooltips
                      intersect: false,
                      mode: 'index'
                    },
                    maintainAspectRatio: false,
                    legend: {
                      display: false,
                    },
                    scales: {
                      xAxes: [
                        {
                          display: false,
                        }
                      ],
                      yAxes: [
                        {
                          id: 'A',
                          type: 'linear',
                          position: 'left',
                          ticks: getRange(this.props.viewChart.singleChart.chart)
                        }
                      ]
                    }
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    viewChart: state.viewChart,
    thing: state.thing.thingData,
    viewModbus: state.thing.viewModbus,
    viewSerialPort: state.thing.viewSerialPort,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    viewChartAction: bindActionCreators(viewChartActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelChart);