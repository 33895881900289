/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Col, Card, CardHeader, CardBody, FormGroup, Button, Collapse, Input, Label } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import thingHelper from './variable'
import StatusIcon from '../../../components/statusIcon'
import { AppSwitch } from '@coreui/react'
import EditIoChannel from './editIoChannels'
import { thingActions } from '../../../actions/thing'
import { viewChartActions } from '../../../actions/viewChart'
import '@zendeskgarden/react-checkboxes/dist/styles.css';
import InputConmmand from './inputCommand'
const tableType = thingHelper.ioTableType

class ChannelsTable extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {}
    this.openEdit = this.openEdit.bind(this)
    this.sendCommand = this.sendCommand.bind(this)
    this.openChart = this.openChart.bind(this)
  }
  openEdit(channel) {
    const editChannelData = {
      alias: channel.alias,
      modbusSetting: channel.data.map((config) => {
        return {
          id: config.id,
          value: config.value,
          alias: config.alias
        }
      })
    }
    this.props.thingAction.updateEditChannelAction('open', true)
    this.props.thingAction.updateEditChannelAction('channelId', channel.id)
    this.props.thingAction.updateEditChannelAction('type', channel.type)
    this.props.thingAction.updateEditChannelAction('data', editChannelData)
  }
  openChart(channel) {
    if (channel.id !== this.props.viewChart.singleChart.id && !this.props.hideInfo) {
      this.props.viewChartAction.updateChartData('id', channel.id)
      this.props.viewChartAction.updateChartData('alias', channel.alias)
      this.props.viewChartAction.updateChartData('chart', {
        label: [],
        data: [],
      })
      this.props.viewChartAction.getChartRecord(this.props.thing.info.id, 'io-report', { id: channel.id })
    }
  }
  sendCommand(channelId, commandValue) {
    let topic = '';
    switch (this.props.viewModbus) {
      case 'io-config':
        topic = 'io-command'
        break;
      case 'gateway-config':
        topic = `gw-${this.props.viewSerialPort}-command`
        break;
      default:
        topic = 'io-command'
        break;
    }
    this.props.thingAction.sendCommand({
      thing: {
        id: this.props.thing.info.id,
        // secret: this.props.thing.info.secret,
      },
      topic,
      command: [{
        id: channelId,
        value: commandValue
      }]
    })
  }
  componentWillUnmount() {
    this.props.viewChartAction.closeChart()
  }
  render() {
    let cardColumn = {
      nameMd: '3',
      idMd: '3',
      statusMd: '3',
      eventsMd: '',
      commandMd: '3'
    }
    if (this.props.hideInfo) {
      cardColumn = {
        nameMd: '2',
        idMd: '2',
        statusMd: '2',
        eventsMd: '3',
        commandMd: '2'
      }
    } else {
      cardColumn = {
        nameMd: '3',
        idMd: '3',
        statusMd: '3',
        eventsMd: '',
        commandMd: '3'
      }
    }
    const channelsCard = tableType.map((item, idx) => {
      const finded = this.props.thing.channels.findIndex((data) => { return data.type === item.type })
      if (finded !== -1) {
        return <Card key={idx}>
          <CardHeader>
            <strong>{item.type}</strong>
          </CardHeader>
          <CardBody>
            <FormGroup row>
              <Col md={cardColumn.nameMd}>Name</Col>
              <Col md={cardColumn.idMd}>ID</Col>
              <Col md={cardColumn.statusMd}>Status</Col>
              {(this.props.hideInfo) &&
                <Col md={cardColumn.eventsMd}>
                  <Label>{item.inputTitle}</Label>
                </Col>
              }
              <Col md={cardColumn.commandMd}>
                <Label>Command</Label>
              </Col>
              {(this.props.hideInfo) &&
                <Col md="1"></Col>
              }
            </FormGroup>
            <hr></hr>
            {this.props.thing.channels.map((channel, idx2) => {
              if (channel.type === item.type) {
                let event = { id: '', value: 0 }
                let pulseMode = 0
                let inputRange = [0, 1]
                let unit = ''
                let errorBackground = ''
                let hoverGray = ''
                let isDisable = ''
                let configure = true
                let findEnable = channel.data.find((modbus) => { return modbus.alias === 'enable' }) || { value: 0 }
                if (!(!!findEnable.value)) {
                  isDisable = 'disable'
                }
                if (channel.type === 'DI') {
                  const findEvent = channel.data.find((data) => { return data.alias === 'events' }) || { id: '', value: 0 }
                  event = findEvent
                }
                if (channel.type === 'RTD') {
                  const findEvent = channel.data.find((data) => { return data.alias === 'switchMode' }) || { id: '', value: 0 }
                  event = findEvent
                  if (!channel.switchDisplay) {
                    if (findEvent.value === 0) {
                      findEnable.value = 0
                      isDisable = 'disable'
                      configure = false
                    } else {
                      findEnable.value = 1
                      isDisable = ''
                    }
                  }
                }
                if (channel.type === 'AI' || channel.type === 'RTD') {
                  const typeCode = channel.data.find((data) => { return data.alias === 'typeCode' });
                  const typeCodeType = thingHelper.aiTypeCode.find((data) => { return parseInt(data.value, 10) === typeCode.value }) || { value: '0', channelType: 'AI', inputType: '', unit: '', text: '', range: [0, 1] }
                  unit = typeCodeType.unit
                  inputRange = typeCodeType.range
                  const findHighAlarm = channel.data.find((data) => { return data.alias === 'isHighAlarm' }) || { value: 0 }
                  const findLowAlarm = channel.data.find((data) => { return data.alias === 'isLowAlarm' }) || { value: 0 }
                  if (findHighAlarm.value !== 0 || findLowAlarm.value !== 0) {
                    errorBackground = 'status-error'
                  }
                }
                if (channel.type === 'DO') {
                  const isPulseMode = channel.data.find((data) => { return data.alias === 'mode' }) || { value: 0 }
                  pulseMode = isPulseMode.value
                }
                if (!(this.props.edit.channelId === channel.id)) {
                  hoverGray = 'hover-gray'
                }
                return <div key={idx2} className={`orio-col ${hoverGray} ${errorBackground} ${isDisable}`}>
                  <FormGroup row onClick={e => { this.openChart(channel) }}>
                    <Col md={cardColumn.nameMd} className="name-col f-weight600">
                      <Label>{channel.alias}</Label>
                    </Col>
                    <Col md={cardColumn.idMd} className="orio-device-text"><Label>{channel.id}</Label></Col>
                    <Col md={cardColumn.statusMd}>
                      {(item.boolean) &&
                        <StatusIcon value={channel.value}></StatusIcon>
                      }
                      {(!item.boolean) &&
                        <Label>{`${channel.value} ${unit}`}</Label>
                      }
                    </Col>
                    {(this.props.hideInfo) &&
                      <Col md={cardColumn.eventsMd}>
                        {(channel.type === 'DI') &&
                          <Label>{event.value}</Label>
                        }
                        {/* {(channel.type === 'AI') &&
                          <Label>{event}</Label>
                        } */}
                        {(channel.type === 'RTD' && channel.switchDisplay) &&
                          <Input
                            type="select"
                            value={event.value}
                            onChange={e => { this.sendCommand(event.id, parseInt(e.target.value, 10)) }}
                          >
                            <option value={0}>1 x RTD(3/4 wire)</option>
                            <option value={1}>2 x RTD(2 wire)</option>
                            <option value={2}>2 x TC</option>
                          </Input>
                        }
                      </Col>
                    }
                    <Col md={cardColumn.commandMd}>
                      <div >
                        {(item.boolean && item.normalCommand && item.type !== 'DI' && !(!!pulseMode)) &&
                          <AppSwitch
                            className={'mx-1'}
                            variant={'pill'}
                            size="sm"
                            checked={(!!channel.value)}
                            label
                            onChange={e => { this.sendCommand(channel.id, +(e.target.checked)) }}
                          />
                        }
                        {(item.boolean && item.normalCommand && (!!pulseMode)) &&
                          <div>
                            <Button size="sm" color="primary" className="orio-btn" onClick={e => { this.sendCommand(channel.id, +(true)) }} >
                              PULSE</Button>
                            <Label>{' '}</Label>
                            <Button size="sm" color="light" className="orio-btn-cancel" onClick={e => { this.sendCommand(channel.id, +(false)) }} >
                              STOP</Button>
                          </div>
                        }
                        {(!item.boolean && item.normalCommand && item.type === 'AO') &&
                          <InputConmmand
                            channelId={channel.id}
                            commandValue={channel.value || 0}
                            min={inputRange[0]}
                            max={inputRange[1]}
                          />
                        }
                      </div>

                    </Col>
                    {(this.props.hideInfo && configure) &&
                      <Col md="1">
                        <Button hidden={(this.props.edit.channelId === channel.id)}
                          color="link" className="orio-link-normal py-0 hideden-button" onClick={e => { this.openEdit(channel) }}>
                          <i className="fa fa-pencil"></i>
                        </Button>
                      </Col>
                    }
                    <Col md="12">
                      <Collapse isOpen={(this.props.edit.open && this.props.edit.channelId === channel.id)}>
                        <EditIoChannel />
                      </Collapse>
                    </Col>
                  </FormGroup>
                  <hr></hr>
                </div>
              }
              return
            })
            }
          </CardBody>
        </Card >
      }
    })

    return (
      <div className="overflow-scrolling">
        <br></br>
        {channelsCard}
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    user: state.user.info,
    thing: state.thing.thingData,
    hideInfo: state.thing.action.hideInfo,
    edit: state.thing.editIoChannel,
    viewModbus: state.thing.viewModbus,
    viewSerialPort: state.thing.viewSerialPort,
    viewChart: state.viewChart
  }
}

function mapDispatchToProps(dispatch) {
  return {
    thingAction: bindActionCreators(thingActions, dispatch),
    viewChartAction: bindActionCreators(viewChartActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsTable);