import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

let middleware = applyMiddleware(
  thunkMiddleware
)
if (process.env.REACT_APP_DEV_TOOL === 'TRUE') {
  middleware = composeWithDevTools(
    applyMiddleware(
      thunkMiddleware
    )
  )
}
export default (initialState) => {
  return createStore(
    rootReducer,
    initialState,
    middleware
  );

}