/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import {
  Card,
  CardBody,
  FormGroup,
  Row,
  Col,
  Button
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { thingActions } from '../../../actions/thing'
import EditIoDiChannel from './editConfig/ioDiConfig'
import EditIoDoChannel from './editConfig/ioDoConfig'
import EditIoAiChannel from './editConfig/ioAiConfig'
import EditIoRtdChannel from './editConfig/ioRtdConfig'



import { connect } from 'react-redux';
class EditIoChannel extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
    }
    this.cancel = this.cancel.bind(this);
    this.submit = this.submit.bind(this);
  }
  cancel() {
    this.props.thingAction.updateEditChannelAction('open', false)
    this.props.thingAction.updateEditChannelAction('channelId', '')
    this.props.thingAction.updateEditChannelAction('type', '')
    this.props.thingAction.updateEditChannelAction('data', {
      alias: '',
      modbusSetting: [],
    })
  }
  submit() {
    const submitObject = {
      thing: {
        id: this.props.thing.id,
        secret: this.props.thing.secret
      },
      channel: {
        id: this.props.edit.channelId,
        alias: this.props.edit.data.alias
      },
      config: this.props.edit.data.modbusSetting.map((modbus) => {
        return { id: modbus.id || '', value: modbus.value || 0 }
      })
    }
    this.props.thingAction.updateChannelData(submitObject)
    this.props.thingAction.updateEditChannelAction('open', false)
    this.props.thingAction.updateEditChannelAction('channelId', '')
    this.props.thingAction.updateEditChannelAction('type', '')
    this.props.thingAction.updateEditChannelAction('data', {
      alias: '',
      modbusSetting: [],
    })
  }
  render() {

    return (
      <Card className="gray-card">
        <CardBody>
          {(this.props.edit.type === 'DI') &&
            <EditIoDiChannel />
          }
          {(this.props.edit.type === 'DO') &&
            <EditIoDoChannel />
          }
          {(this.props.edit.type === 'RELAY') &&
            <EditIoDoChannel />
          }
          {(this.props.edit.type === 'AI') &&
            <EditIoAiChannel />
          }
          {(this.props.edit.type === 'RTD') &&
            <EditIoRtdChannel />
          }
          <FormGroup>
            <Row>
              <Col md="6">
              </Col>
              <Col md="6">
                <div align="right">
                  <Button color="light" className="orio-btn-cancel" onClick={e => { this.cancel() }} >
                    Cancel</Button>
                  <span>{' '}</span>
                  <Button color="primary" className="orio-btn" onClick={e => { this.submit() }} >
                    Save</Button>
                </div>
              </Col>
            </Row>
          </FormGroup>
        </CardBody>

      </Card>
    );
  }
}


function mapStateToProps(state) {
  return {
    thing: state.thing.thingData.info,
    channels: state.thing.thingData.channels,
    edit: state.thing.editIoChannel,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    thingAction: bindActionCreators(thingActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditIoChannel);