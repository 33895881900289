import { shareFunction } from './shareFunction'
import { ENV } from '../helpers/env'
import { thingActions } from './thing'

export const mapActions = {
  updatePage,
  changeDevice,
  updateMapObject,
  updateThingCoordinates,
  showStationDevice,
  changeMapCenterZoom
}


function updatePage(page) {
  return dispatch => {
    dispatch(shareFunction.sidebarFix('lg'))
    dispatch(shareFunction.updatePage(page))
  }

}

function updateMapObject(key, value) {
  return { type: 'UPDATE_MAP_OBJECT', key, value }
}

function updateThingCoordinates(thingId, coordinates) {
  return dispatch => {
    dispatch(shareFunction.startLoading())
    fetch(`${ENV.SERVER_API}/thing/device-update-data/${thingId}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        coordinates
      })
    }).then((response) => {
      if (response.status === 200) {
        dispatch(thingActions.getThing())
        dispatch(shareFunction.stopLoading())
        shareFunction.notify('success', 'Action Success')
      } else {
        dispatch(shareFunction.stopLoading())
        shareFunction.notify('error', 'Action Failed')
      }
    }).catch((error) => {
      dispatch(shareFunction.stopLoading())
      shareFunction.notify('error', 'Action Failed')
      console.log(error)
    })
  }
}


function changeDevice(value) {
  return { type: 'UPDATE_MAP_CENTER', value }
}

function showStationDevice(stationNum, show) {
  return { type: 'CHANG_STATION_SHOW_STATUS', value: { stationNum, show } }
}

function changeMapCenterZoom(data) {
  return {
    type: 'CHANGE_MAP_CENTER_ZOOM', value: {
      zoom: data.zoom,
      center: data.center
    }
  }
}