/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Col, Row, Button, CardBody } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { thingActions } from '../../actions/thing'
import { viewChartActions } from '../../actions/viewChart'
import { shareFunction } from '../../actions/shareFunction'
import ThingInformation from './common/thingInfo'
import ThingHeader from './common/thingHeader'
import ChannelsTable from './common/channelsTable'
import GatewayChannelsTable from './common/gatewayChannelsTable'
import GatewayModbusConfig from './common/gatewayModbusConfig'
import ThingImage from './common/thingImage'
import EditImage from './common/thingImageModel'
import DeleteThing from './common/deleteThingModel'
import UploadModbusConfig from './common/uploadConfigModel'
import ChannelChart from './common/channelChart'
import socket from '../../helpers/socket'
import moment from 'moment'
class ThingInfo extends Component {

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      thingId: '',
      modbusConfig: '',
      serialPort: 1,
    };
    this.props.commomAction.updatePage('devices')
    this.handleChart = this.handleChart.bind(this)
  }
  handleChart(message) {
    if (this.props.viewChart.realChart) {
      message.data.map((payload) => {
        if (payload.id === this.props.viewChart.id) {
          if (payload.value !== undefined) {
            this.props.viewChartAction.updateChartRecord(payload.id, moment(moment().utc().format()).valueOf(), payload.value)
          }
        }
      })
    }
  }
  componentWillMount() {
    this.props.commomAction.changeHeaderUrl(true)
    this.props.thingAction.getThingInfo(this.props.match.params.thingId)
    this.props.thingAction.getThingChannels(this.props.match.params.thingId)
    socket.emit('subscribe:DEVICE_ID', this.props.match.params.thingId);
    socket.on('DEVICE_INFO_DATA', message => {
      this.props.thingAction.updateReducer('UPDATE_DEVICE_INFO_BY_SOCKET', message.data.name, message.data.value)
    });

  }
  componentDidUpdate() {
    if ((this.state.thingId !== this.props.match.params.thingId)) {
      this.props.viewChartAction.closeChart()
      socket.emit('disSubscribe:DEVICE_ID', this.state.thingId);
      this.setState({ thingId: this.props.match.params.thingId })
      this.props.thingAction.getThingInfo(this.props.match.params.thingId)
      this.props.thingAction.getThingChannels(this.props.match.params.thingId)
      socket.emit('subscribe:DEVICE_ID', this.props.match.params.thingId);
    }
    if (this.state.serialPort !== this.props.viewSerialPort) {
      socket.off(`CHANNELS_DATA-gw-${this.state.serialPort}-report`);
      this.setState({ serialPort: this.props.viewSerialPort });
      socket.on(`CHANNELS_DATA-gw-${this.props.viewSerialPort}-report`, message => {
        this.props.thingAction.updateGwDataBySocket(message.data)
        this.handleChart(message)
      });
    }
    if ((this.state.modbusConfig !== this.props.match.params.modbusConfig)) {
      this.setState({ modbusConfig: this.props.match.params.modbusConfig })
      switch (this.props.match.params.modbusConfig) {
        case 'io-config':
          socket.off('CHANNELS_DATA');
          socket.off(`CHANNELS_DATA-gw-${this.props.viewSerialPort}-report`);
          socket.on('CHANNELS_DATA', message => {
            this.props.thingAction.updateDataBySocket(message.data)
            this.handleChart(message)
          });
          this.props.thingAction.updateThingAction('editGwConfig', false)
          this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'viewSerialPort', 1)
          this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'viewModbus', 'io-config')
          this.props.thingAction.getThingChannels(this.props.match.params.thingId)
          break;
        case 'gateway-config':
          socket.off(`CHANNELS_DATA`);
          socket.off(`CHANNELS_DATA-gw-${this.props.viewSerialPort}-report`);
          socket.on(`CHANNELS_DATA-gw-${this.props.viewSerialPort}-report`, message => {
            this.props.thingAction.updateGwDataBySocket(message.data)
            this.handleChart(message)
          });
          // socket.on(`CHANNELS_DATA-gw-${this.props.viewSerialPort}`, message => {
          //   console.log(message.data)
          // });
          this.props.thingAction.updateReducer('UPDATE_THING_OBJECT', 'viewModbus', 'gateway-config')
          this.props.thingAction.getThingGatewayChannels(this.props.match.params.thingId, this.props.viewSerialPort)
          break;
        default:
          break;
      }
    }
  }
  componentWillUnmount() {
    this.props.commomAction.changeHeaderUrl(false)
    this.props.thingAction.updateThingAction('editGwConfig', false)
    socket.emit('disSubscribe:DEVICE_ID', this.state.thingId);
    socket.off('CHANNELS_DATA');
    socket.off(`CHANNELS_DATA-gw-${this.props.viewSerialPort}-report`);
    socket.off('DEVICE_INFO_DATA');
  }

  render() {
    let columnWeight = '6'
    if (this.props.hideInfo) {
      columnWeight = '12'
    } else {
      columnWeight = '6'
    }

    return (
      <div className="animated fadeIn" >
        <Row className="pl-3">
          <Col xs="12" sm={columnWeight}>
            <br></br>
            <div align="right">
              <ThingHeader></ThingHeader>
            </div>
            <br></br>
            {(this.state.modbusConfig === 'io-config' && !(this.props.editConfig)) &&
              < ChannelsTable />
            }
            {(this.state.modbusConfig === 'gateway-config' && !(this.props.editConfig)) &&
              <GatewayChannelsTable />
            }
            {(this.state.modbusConfig === 'gateway-config' && (this.props.editConfig)) &&
              <GatewayModbusConfig />
            }
          </Col>
          <Col xs="12" sm="6" className="orio-background-gray" hidden={this.props.hideInfo}>
            <div className="overflow-scrolling-thingInfo">
              <Button
                size="sm"
                color="primary"
                className="orio-btn restore-btn-style restore-col-style"
                onClick={event => { this.props.thingAction.thingHide() }}>
                <i className="fa fa-chevron-right "></i>
              </Button>
              <Row >
                <Col md="12">
                  <CardBody color="secondery">
                    {(this.props.viewChart.id !== '') &&
                      <ChannelChart />
                    }
                    <br></br>
                    <ThingInformation thing={this.props.thing.info}></ThingInformation>
                    <br></br>
                    <br></br>
                    <ThingImage></ThingImage>
                  </CardBody>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <EditImage />
        <DeleteThing />
        <UploadModbusConfig />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    thing: state.thing.thingData,
    hideInfo: state.thing.action.hideInfo,
    editConfig: state.thing.action.editGwConfig,
    viewSerialPort: state.thing.viewSerialPort,
    viewChart: state.viewChart.singleChart
  }
}

function mapDispatchToProps(dispatch) {
  return {
    thingAction: bindActionCreators(thingActions, dispatch),
    commomAction: bindActionCreators(shareFunction, dispatch),
    viewChartAction: bindActionCreators(viewChartActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThingInfo);  