const initialState = {
  modelOpen: false,
  verify: {
    deviceName: true,
    serialNumber: true,
    coordinate_lng: true,
    coordinate_lat: true,
    thingModel: true
  },
  deviceInfo: {
    deviceName: null,
    serialNumber: null,
    coordinate_lng: 0,
    coordinate_lat: 0,
    thingModel: null,
    modelDI: 0,
    modelDO: 0,
    modelAI: 0,
    modelAO: 0,
    serialPort: 1,
  },
};

const createDeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_CREATE_DEVICE_OBJECT':
      return Object.assign({}, state, { [action.key]: action.value });
    case 'UPDATE_CREATE_DEVICE_INFO_OBJECT':
      return Object.assign({}, state, {
        deviceInfo: Object.assign({}, state.deviceInfo, {
          [action.key]: action.value
        })
      });
    case 'UPDATE_CREATE_DEVICE_INFO_VERIFY':
      return Object.assign({}, state, {
        verify: Object.assign({}, state.verify, {
          [action.key]: action.value
        })
      });
    case 'CLEAR_CREATE_DEVICE':
      return initialState;
    default:
      return state;
  }
}

export default createDeviceReducer